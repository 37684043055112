import dayJs from 'dayjs'
import { ActivityTopic } from './ActivityTopic'
import './ActivityCard.scoped.scss'

// Check if an entitiy url is an 'internal' URL and if so, convert to the correct path.
// Internal URLs have the form "internal://:entityName/:entityId"
const entityURL = (externalUrl) => {
  let url
  try {
    url = new URL(externalUrl)
  } catch {
    return externalUrl
  }
  if (url.protocol === 'internal:') {
    const [entityName, entityId, ...rest] = url.pathname.replace(/^[^\w]+/, '').split('/')
    switch (entityName) {
      case 'campaign':
        return `/admin/campaigns/${entityId}/overview`
      default:
        return `/admin/${entityName}/${entityId}`
    }
  } else {
    return externalUrl
  }
}

const entityLink = (entity) => (
  <a className="blue-link" href={entityURL(entity.externalUrl)}>
    {entity.source}
  </a>
)

const ActivityCard = ({ activity }) => {
  const linkableEntities = activity.activityEntities.filter(
    (entity) => entity.externalUrl
  )
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => setIsExpanded(!isExpanded)
  let activityBody = activity.body

  linkableEntities.forEach(entity => {
    const campaignName = entity.name

    if (activityBody.includes(campaignName)) {
      const replacementText = `<a href="${entityURL(entity.externalUrl)}" style="font-weight: 600; font-size: 14px;" >${campaignName}</a>`
      activityBody = activityBody.replace(campaignName, replacementText)
    }
  })

  return (
    <div className="activity-log-item">
      <div className="icon small circle">
        {activity.topic === 'call' && <SvgIconsActivityLogCall />}
        {activity.topic === 'sms-blast' && <SvgIconsActivityLogSms />}
        {activity.topic === 'sms-survey' && <SvgIconsActivityLogSms />}
        {activity.topic === 'sms-conversation' && <SvgIconsActivityLogSms />}
        {activity.topic === 'email-blast' && <SvgIconsActivityLogEmail />}
        {activity.topic === 'zoom' && <SvgIconsActivityLogCall />}
        {activity.topic === 'webinar' && <SvgIconsActivityLogCall />}
      </div>

      <div className="purple-40 margin-left half" style={{ minWidth: '65px' }}>
        {dayJs(activity.createdAt).format('h:mm a')}
      </div>
      <div className="topic margin-left">
        <ActivityTopic topic={activity.topic} />
      </div>
      <div className="body">
        <p className="purple-40 smaller body-margins" dangerouslySetInnerHTML={{ __html: activityBody }} />
      </div>
    </div>
  )
}

export default ActivityCard
