import { Popover } from '@varld/popover'
import './Options.scoped.scss'
import Tabs from "./Tabs"
import Tab from "./Tab"
import TabPanel from "Shared/components/Tabs/TabPanel"
import { max, min } from 'lodash'

function RadioFilter({ onChange, filter, close, channel }) {
  const { name, options } = filter

  return (
    <div className="radio-filter">
      {options.map((option, index) => (
        <div key={index} className="radio-option">
          <label>
            <input
              type="radio"
              name={name}
              {... (channel && channel !== '' ? { checked: channel === option } : {})}
              onClick={() => {
                onChange(option)
                close()
                setTimeout(() => {
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                  });
                }, 100);
              }}
            />
            {option}
          </label>
        </div>
      ))}
    </div>
  )
}

const Options = ({ channelSelection, channelToLoad}) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (index) => {
    setTabIndex(index)
  }

  const filters = [
    { 'name': 'Channels', 'desc': 'What channel was used', 'options': ['SMS', 'Email', 'Call', 'All'] },
  ]

  const changeFilter = (value) => {
    channelSelection(value)
  }

  const filterStyle = {
    position: 'relative',
    right: '30px'
  }

  return (
    <div className="top-bar">
      <h3 className="no-margin-top">Activity Log</h3>
      <div className="options-bar">
        <Popover popover={({ close }) => {
          return (
            <div className="popup-list popup" style={filterStyle}>
              <div className="filter-popup">
                <div>
                  <Tabs value={tabIndex} onChange={setTabIndex}>
                    {filters.map((filter, index) => {
                      return (
                        <Tab
                          key={index}
                          label={filter.name}
                          desc={filter.desc}
                          value={index}
                          onClick={() => handleTabChange(index)}
                          selected={tabIndex === index}
                        />)
                    })}
                  </Tabs>
                </div>
                <div className="panel">
                  {filters.map((filter, index) => (
                    <TabPanel key={index} value={tabIndex} index={index}>
                      {/* Use a different RadioFilter component for each filter */}
                      <RadioFilter filter={filter} onChange={changeFilter} close={close} channel={channelToLoad} />
                    </TabPanel>))}
                </div>
              </div>
            </div>
          )
        }}>
          <button className="button secondary small" type="button">
            <SvgIconsPlus />
            <p>Add filter</p>
          </button>
        </Popover>
      </div>
    </div>
  )
}

export default Options
