
import { useAct } from 'FlowBuilder/store'
import InlineCkEditor from 'Shared/components/CKEditor/InlineCkEditor'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'

export default function EditContent() {
  const act = useAct()
  const slug = useSel(s => s.local.modal.slug)
  const field = useSel(s => s.builder.flow.template.fields.find(f => f.slug === _.snakeCase(slug)))
  const value = useSel(s => s.builder.templateFields[slug])
  const mergeTags = useSel(s => s.builder.mergeTags)
  const [sourceMode, setSourceMode] = useState(false)

  function onChange(value) {
    act.builder.setTemplateField({ slug, value })
  }

  function handleFileUploaded(blobIds){
    act.builder.setTemplateField({ slug, value: blobIds[0] })
    act.local.closeModal()
  }

  return <div>
    <div className='row'>
      <div className='column remaining'>
        <p className='no-margin-top dark-grey'>{field.label}</p>
        {field.wysiwyg && <InlineCkEditor
          value={value}
          sourceMode={sourceMode}
          setSourceMode={setSourceMode}
          onUpdate={onChange}
          mergeTags={mergeTags}
        />}
        {!field.wysiwyg && !field.image && <div className='form-group'>
          <input type='text' className='full-width' value={value} onChange={e => onChange(e.target.value)} />
        </div>}
        {field.image && <div className="form-group">
          <FileUploader
            onUploaded={handleFileUploaded}
          />
        </div>}
      </div>
    </div>
  </div>
}



