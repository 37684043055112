import './Question.scoped.scss'

import QuestionPhonePreview from 'Shared/components/QuestionPhonePreview'
import InstructionsTextarea from './InstructionsTextarea'
import Answer from './Answer'
import { useStore } from 'react-redux'
import detectCycle from 'CampaignBuilder/detectCycle'
import PillEditor from 'Shared/components/PillEditor/PillEditor'
import { Popover } from '@varld/popover'
import NextAction from './NextAction'
import Button from 'Shared/components/Button'
import parsePhoneNumber from 'libphonenumber-js/max'
import { useCallback } from 'react'
import useAct from '../hooks/useAct'
import Checkbox from 'Shared/components/Checkbox'

const ensureNumber = (n) => {
  const val = +n
  return isNaN(val) ? 0 : val
}

const labelMappings = {
  intro: 'Introduction',
  instruction: 'Instruction',
  multiple_choice: 'Select one',
  multiple_select: 'Select multiple',
  send_sms: 'Send SMS - Instruction',
  text: 'Question',
  score: 'Ranking',
  transfer: 'Transfer call - Instruction',
}

const BRANCHING_QUESTION_TYPES = ['multiple_choice']
const GO_TO_NEXT_QUESTION_TYPES = [
  'multiple_select',
  'score',
  'instruction',
  'text',
  'send_sms',
  'transfer',
]

export default function Question({
  id,
  campaignType,
  effortId,
  effortName,
  mergeTags,
  isCampaignControlled,
  enableOptOut,
  blastKind,
}) {
  const act = useAct()
  const store = useStore()
  const questions = useSel((s) => _.values(s.questions.entities).filter((q) => q.active))
  const {
    question,
    instructions,
    questionType,
    digitOrder,
    settings = {},
    action,
    nextQuestionId,
  } = useSel((s) => s.questions.entities[id])
  const { textMessageBody, toPhoneNumber } = settings || {}
  const answers = useSel((s) =>
    _.orderBy(
      _.values(s.answers.entities).filter((a) => a.questionId === id && a.active),
      'digitOrder'
    )
  )

  // show advanced logic if either there's: an effort/goal on the campaign;
  // one of the answers isn't set to next_question & sets_goal = false;
  // if the next action of the question isn't to go to the next question
  const hasAdvancedLogic =
    effortId ||
    (answers.length > 0 &&
      _.some(answers, (a) => a.action !== 'next_question' || a.setsGoal === true)) ||
    (action && action !== 'next_question')

  const answerIds = answers.map((a) => a.id)
  const label = labelMappings[questionType]
  const hasAnswers = !_.includes(
    ['instruction', 'intro', 'send_sms', 'transfer', 'score'],
    questionType
  )
  const includeOptOut = campaignType === 'sms_survey' && enableOptOut && digitOrder === 1
  const isWhatsappFirstMessage =
    campaignType === 'sms_survey' && blastKind === 'whatsapp' && digitOrder === 1
  const canMove = questionType !== 'intro' && !isWhatsappFirstMessage
  const readOnly = isWhatsappFirstMessage
  const moveableOffset = campaignType === 'phone' ? 1 : 0
  const canMoveUp =
    digitOrder > 1 + moveableOffset && !(blastKind === 'whatsapp' && digitOrder === 2)
  const canMoveDown = digitOrder < questions.length

  function move(down) {
    const newOrder = down ? digitOrder + 1 : digitOrder - 1
    const state = store.getState()
    const answers = _.values(state.answers.entities).filter((a) => a.active)

    let affectedIds = _.orderBy(
      questions.filter((e) => e.id !== id),
      'digitOrder'
    ).map((e) => e.id)
    affectedIds.splice(newOrder, 0, id)

    // Apply update to copy of questions to check for cycles
    const localQuestions = questions.map((q, i) => {
      const newDigitOrder = affectedIds.indexOf(q.id) + 1
      return { ...q, digitOrder: newDigitOrder }
    })

    act.scriptBuilder.questions.moveDigitOrder(id, newOrder - 1, { active: true })
  }

  const saveSettings = (settings) =>
    act.scriptBuilder.questions.upsertSettings(id, settings)

  useEffect(() => {
    const value = String(toPhoneNumber).replace('+440', '+44')
    const country = String(value)[0] === '+' ? '' : window.ENV.COUNTRY.toUpperCase()
    const parsed = parsePhoneNumber(value, country)
    if (parsed && parsed.isValid()) {
      // Normalize
      saveSettings({ toPhoneNumber: parsed.number })
    }
  }, [toPhoneNumber])

  return (
    <div className="question-block row">
      <div className="column remaining">
        <div className={`question question-${questionType}`}>
          <div className="attributes">
            <label>{label}</label>
            <div
              className={`content-editor condensed ${hasAnswers ? 'transparent' : 'bordered'}`}
            >
              {readOnly ? (
                <input type="text" value={question} disabled className="full-width" />
              ) : (
                <PillEditor
                  condensed={true}
                  initialValue={question}
                  onChange={useCallback(
                    (body) => act.scriptBuilder.questions.upsert(id, { question: body }),
                    [id]
                  )}
                  availableTags={mergeTags}
                />
              )}
            </div>
          </div>

          {questionType === 'text' && (
            <div className="indent">
              <p>This is an open question where any answer can be entered.</p>
            </div>
          )}

          {campaignType !== 'sms_survey' &&
            ['multiple_choice', 'multiple_select', 'text', 'score'].includes(
              questionType
            ) && (
              <div className="attributes">
                <label></label>
                <Checkbox
                  label="Require an answer to be given"
                  checked={settings.required}
                  onChange={(e) => saveSettings({ required: e.target.checked })}
                />
              </div>
            )}

          {questionType === 'send_sms' && (
            <div className="attributes">
              <label>Text message content</label>
              <div className={`content-editor condensed bordered`}>
                <PillEditor
                  condensed={true}
                  initialValue={textMessageBody}
                  onChange={(textMessageBody) =>
                    act.scriptBuilder.questions.upsertSettings(id, { textMessageBody })
                  }
                  availableTags={mergeTags}
                />
              </div>
            </div>
          )}

          {questionType === 'transfer' && (
            <div className="attributes">
              <label>Phone number to transfer to</label>
              <div className={`content-editor condensed bordered`}>
                🇬🇧 +44{' '}
                <input
                  type="text"
                  value={String(toPhoneNumber).replace('+44', '')}
                  onChange={(e) =>
                    act.scriptBuilder.questions.upsertSettings(id, {
                      toPhoneNumber: '+44' + String(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          )}

          {hasAnswers && questionType !== 'text' && (
            <div className="answers">
              {answerIds.map((id) => (
                <Answer
                  id={id}
                  key={id}
                  showAdvanced={BRANCHING_QUESTION_TYPES.includes(questionType)}
                  effortId={effortId}
                  effortName={effortName}
                  readOnly={readOnly}
                  mergeTags={mergeTags}
                />
              ))}
              {!readOnly && (
                <a
                  className="button naked add-answer"
                  onClick={() =>
                    act.scriptBuilder.answers.add({
                      questionId: id,
                      digitOrder: answers.length,
                      action: 'next_question',
                    })
                  }
                >
                  Add an answer
                </a>
              )}
            </div>
          )}

          {((!settings.required || GO_TO_NEXT_QUESTION_TYPES.includes(questionType)) && !(campaignType === 'sms_survey' && questionType === 'multiple_choice')) && (
            <div className="attributes">
              <label>
                After this {questionType === 'instruction' ? 'instruction' : 'question'}
              </label>
              <NextAction
                questionId={id}
                action={action}
                nextQuestionId={nextQuestionId}
                onChange={(option) =>
                  act.scriptBuilder.questions.upsert(id, option.value)
                }
              />
            </div>
          )}

          {questionType === 'score' && (
            <div className="attributes">
              <label>Min</label>
              <input
                name="min"
                type="text"
                size="3"
                value={settings.min}
                onChange={(e) => saveSettings({ min: ensureNumber(e.target.value) })}
              />
              <label>Max</label>
              <input
                name="max"
                type="text"
                size="3"
                value={settings.max}
                onChange={(e) => saveSettings({ max: ensureNumber(e.target.value) })}
              />
              <label>Default</label>
              <input
                name="default"
                type="text"
                size="3"
                value={settings.default}
                onChange={(e) => saveSettings({ default: ensureNumber(e.target.value) })}
              />
            </div>
          )}

          <div className="divider"></div>

          {questionType !== 'intro' && !isCampaignControlled && (
            <>
              <div className="attributes flex-start">
                <InstructionsTextarea
                  id={id}
                  initialInstructions={instructions}
                  act={act}
                />
              </div>
              <div className="divider"></div>
            </>
          )}

          <div className="actions">
            {canMove && (
              <>
                {canMoveUp && (
                  <Button
                    smallest
                    secondary
                    extraClass="small-square-button margin-right move-up"
                    onClick={() => move(false)}
                  >
                    <SvgIconsUp />
                  </Button>
                )}
                {canMoveDown && (
                  <Button
                    smallest
                    secondary
                    extraClass="small-square-button margin-right move-down"
                    onClick={() => move(true)}
                  >
                    <SvgIconsDown />
                  </Button>
                )}
              </>
            )}

            <div className="divider vertical"></div>

            {!readOnly && (
              <a
                className="delete margin-bottom half"
                onClick={() => act.scriptBuilder.questions.markInactive(id)}
              >
                <SvgIconsDelete />
              </a>
            )}
          </div>
        </div>
      </div>
      {(campaignType === 'sms_survey' || questionType === 'send_sms') && (
        <div className="column quarter">
          <QuestionPhonePreview
            includeOptOut={includeOptOut}
            questionId={id}
            showCharacterCount={blastKind === 'sms'}
          />
        </div>
      )}
    </div>
  )
}
