import Checkbox from 'Shared/components/Checkbox'

export default function CheckboxField({onChange, value, label, ...props}) {
  const handleClick = (e) => {
    onChange(e.target.checked)
  }

  return (
    <div className="field">
      <Checkbox label={label} {...props} checked={value} onClick={handleClick} />
    </div>
  )
}

