import CodeMirrorEditor from 'Shared/components/CodeMirrorEditor'

const JsonField = ({name, value, onChange, onValidityChange}) => {
  const [showFieldsError, setShowFieldsError] = useState(false)

  const updateFields = (val) => {
    try {
      JSON.parse(val)
      onValidityChange(true)
      setShowFieldsError(false)
    }
    catch (error) {
      onValidityChange(false)
      setShowFieldsError(true)
    }
    onChange(val)
  }

  return (
    <>
      <label className="margin-bottom half">{name}</label>
      <CodeMirrorEditor
        value={value}
        onChange={updateFields}
        mode='json'
      />
      {showFieldsError && (
        <p className="error">{name} JSON is invalid</p>
      )}
    </>
  )
}

export default JsonField
