import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import './TemplatePicker.scoped.scss'
import Person from '@material-ui/icons/Person'
import Business from '@material-ui/icons/Business'
import Public from '@material-ui/icons/Public'
import Add from '@material-ui/icons/Add'
import { CircularProgress } from '@material-ui/core'
import restClient from 'Shared/hooks/restClient'

const TemplatePicker = () => {
  const act = useAct()

  const backUrl = useSel((s) => s.campaign.adminUrl)
  const organisationId = useSel((s) => s.currentUser.primaryOrganisationIds[0])
  const clientName = useSel((s) => s.currentUser.clientName)
  const organisationName = useSel((s) => s.currentUser.organisationName)
  const switchingTemplate = useSel((s) => s.emailBuilder.switchingTemplate)
  const hideBlankTemplate = window.ENV.SETTINGS.hide_blank_email_template

  // Open if we're editing an email blast, not an emailTemplate, and template hasn't already been chosen
  // Also open if we're in the process of switching templates for an email blast
  const wantOpenBySwitchTemplate = useSel(
    (s) => !s.emailBuilder.editingTemplate && s.emailBuilder.switchingTemplate
  )
  const wantOpenBecauseEmpty = useSel(
    (s) => !s.emailBuilder.editingTemplate && !s.campaign.emailBlast.emailTemplateId
  )

  const curBlocks = useSel((s) => s.blocks.present.ids)
  const [showBlank, setShowBlank] = useState(false)
  useEffect(() => {
    if (!showBlank && curBlocks?.length) {
      setShowBlank(true)
    }
  }, [curBlocks])

  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeFilter, setActiveFilter] = useState('global')

  const loadTemplates = (filter) => {
    setLoading(true)
    setActiveFilter(filter)
    const params = new URLSearchParams({})
    if (filter === 'user') {
      params.append('mine', 'true')
    }
    if (filter === 'org') {
      params.append('organisation_id', organisationId)
    }
    if (filter === 'blank') {
      chooseTemplate()
    }
    restClient
      .get(`/api/email_templates/search?${params.toString()}`)
      .then(({ data }) => {
        setTemplates(data)
        setLoading(false)
      })
  }

  const chooseTemplate = (emailTemplateId) => {
    if (
      switchingTemplate &&
      !confirm(
        'You will lose your current email if you change the template, are you sure?'
      )
    ) {
      act.emailBuilder.upsert({ switchingTemplate: false })
      return
    }
    // Blank template
    if (!emailTemplateId) {
      act.emailBuilder.upsert({ switchingTemplate: false })
      act.campaign.upsertEmailBlast({
        emailTemplateId: null,
        emailTemplate: null,
      })
      setShowBlank(true)
      act.emailBuilder.upsert({
        headerHtmlRendered: null,
        footerHtmlRendered: null,
        hasUnsubscribeBlock: true,
      })
      act.email.blocks.setAll([])
      return
    }
    setLoading(true)
    restClient.get(`/api/email_templates/${emailTemplateId}`).then(({ data }) => {
      console.debug(data)
      setLoading(false)
      // Remove the IDs that come down, and replace with uuids
      act.email.blocks.setAll(data.emailBlastBlocks.map((b) => ({ ...b, id: uuidv4() })))
      act.emailBuilder.upsert({
        headerHtmlRendered: data.headerHtmlRendered,
        footerHtmlRendered: data.footerHtmlRendered,
        hasUnsubscribeBlock: data.hasUnsubscribeBlock,
        styles: data.styles,
      })
      act.campaign.upsertEmailBlast({ emailTemplateId, emailTemplate: data })
    })
    act.emailBuilder.upsert({ switchingTemplate: false })
  }

  useEffect(() => {
    loadTemplates('global')
  }, [])

  const FilterItem = ({ title, subTitle, icon, filter }) => (
    <li
      className={activeFilter === filter ? 'active' : null}
      onClick={() => loadTemplates(filter)}
    >
      {icon}
      <div>
        <h4>{title}</h4>
        {subTitle}
      </div>
    </li>
  )

  const Template = ({ id, name, previewUrl }) => (
    <div>
      <div className="thumbnail" onClick={() => chooseTemplate(id)}>
        {previewUrl && <img src={previewUrl} alt={`${name} preview`} />}
      </div>
      {name}
    </div>
  )

  const cancel = () => {
    if (wantOpenBySwitchTemplate) {
      act.emailBuilder.upsert({ switchingTemplate: false })
    } else {
      window.location.href = backUrl
    }
  }

  return (
    <BackdropModal
      title="Pick a template"
      open={wantOpenBySwitchTemplate || (wantOpenBecauseEmpty && !showBlank)}
      tall
      noContentWrapper
      onCancel={cancel}
    >
      <div className="main">
        <nav className="categories">
          <ul>
            <FilterItem
              title="My templates"
              subTitle="Saved by you"
              filter="user"
              icon={<Person />}
            />
            <FilterItem
              title="Your organisation"
              subTitle={
                <>
                  Created by other users in <strong>{organisationName}</strong>
                </>
              }
              filter="org"
              icon={<Business />}
            />
            <FilterItem
              title="Global templates"
              subTitle={
                <>
                  Templates for everyone in <strong>{clientName}</strong>
                </>
              }
              filter="global"
              icon={<Public />}
            />
            {!hideBlankTemplate && (
              <FilterItem
                title="Create from scratch"
                subTitle="Start from a blank template"
                filter="blank"
                icon={<Add />}
              />
            )}
          </ul>
        </nav>
        <div className="email-templates">
          {!loading && !templates.length && <h2>No {activeFilter} templates</h2>}
          {!loading && !!templates.length && (
            <h2>
              {templates.length} {activeFilter} templates
            </h2>
          )}
          <div className={!loading && templates.length ? 'list' : 'listEmpty'}>
            {loading && <CircularProgress />}
            {!loading &&
              templates.map((template) => <Template key={template.id} {...template} />)}
          </div>
        </div>
      </div>
    </BackdropModal>
  )
}

export default TemplatePicker
