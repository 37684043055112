import { store } from 'FlowBuilder/store'
import { Provider } from 'react-redux'
import PageContainer from 'FlowBuilder/components/PageContainer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function FlowBuilder(params) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <PageContainer {...params} />
        <ToastContainer
          position="top-right"
          autoClose={false}
          theme="light"
        />
      </ErrorBoundary>
    </Provider>
  )
}

