import { useAct } from 'FlowBuilder/store'
import Select from 'react-select'

export default function ShareActionForm({ actionId }) {
  const act = useAct()

  const action = useSel(s => s.actions.entities[s.local.selectedActionId])
  const config = action.config || { channels: [] }
  const value = config.channels || []

  function update(data) {
    act.actions.upsert(actionId, { config: { ...config, channels: data } })
  }

  const selectOptions = [
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'bluesky', label: 'Bluesky' },
  ]

  return <div className="box narrow">
    <h2 className='no-margin-top'>Share Page</h2>
    <div className='field'>
      <label className="bold">Share Channels</label>
      <Select isMulti options={selectOptions} value={selectOptions.filter(o => _.includes(value, o.value))} onChange={o => update(o.map(x => x.value))} />
    </div>
  </div>
}
