export default function flowTemplate(dis, store, client) {
  async function load(id) {
    return client.get(`/api/flow_templates/${id}`)
      .then(({ data }) => {
        data.fields = JSON.stringify(data.fields, null, 2)
        dis({ type: 'flowTemplate/set', payload: data })
      })
  }
  
  async function commit() {
    const localData = store.getState().flowTemplate
    let fields = localData.fields
    try {
      fields = JSON.parse(store.getState().flowTemplate.fields)
    } catch (e) {
      dis({ type: 'local/upsert', payload: { error: 'Please check JSON validity.' } })
      console.log(e)
      return Promise.resolve()
    }
    
    return client.put(`/api/flow_templates/${localData.id}`, { ...localData, fields: fields })
    .then(({ data }) => {
      data.fields = JSON.stringify(data.fields, null, 2)
      dis({ type: 'flowTemplate/set', payload: data })
      dis({ type: 'local/upsert', payload: { error: null } })
    }).catch((e) => {
      if (e.response.status === 422) {
        dis({ type: 'local/upsert', payload: { error: 'Please check the details you entered' } })
      } else {
        dis({ type: 'local/upsert', payload: { error: 'Error saving' } })
        console.error(e)
      }
    })
  }
  
  async function remove(id) {
    return client.delete(`/api/flow_templates/${id}`).catch((e) => {
      dis({ type: 'local/upsert', payload: { error: 'Error saving' } })
      console.error(e)
      throw e;
    })
  }
  
  async function upsert(data) {
    dis({ type: 'flowTemplate/upsert', payload: data })
  }

  return { load, commit, remove, upsert }
}
