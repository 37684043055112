import AccessControlContent from './AccessControlContent'
import { Provider } from 'react-redux'
import store from '../store'


export default function AccessControl({ entityType, entityId, entityName }) {
  return <Provider store={store}>
    <AccessControlContent entityType={entityType} entityId={entityId} entityName={entityName} />
  </Provider>
}

