import AsyncSelect from 'Shared/components/AsyncSelect'
import Step from './Step'
import MenuListWithFooter from 'Shared/components/MenuListWithFooter'
import { components } from 'react-select'
import Tag from '../../../Shared/components/Tag'
import AddSenderModal from 'Shared/components/EmailSender/AddSenderModal'

function EmailFromShortDescriptor({ complete, senderEmail, senderName }) {
  return (
    <div>
      {complete && (
        <p className="no-margin-bottom">
          {senderName} &lt;{senderEmail}&gt;
        </p>
      )}
      {!complete && (
        <p className="incomplete no-margin-bottom">Who does the Email come from?</p>
      )}
    </div>
  )
}

const scopeColorMap = {
  global: 'purple',
  organisation: 'orange',
  user: 'green',
}

const EmailSenderDescriptor = ({ name, email, scope }) => {
  // TODO: Remove this once we're able to get the correct data
  if (!(name && email)) {
    return <></>
  }
  return (
    <>
      {name} &lt;{email}&gt;&nbsp;
      <Tag color={scopeColorMap[scope]}>{scope}</Tag>
    </>
  )
}

const EmailSenderOption = (props) => {
  const {
    data: { name, email, scope },
  } = props
  return (
    <components.Option {...props}>
      <EmailSenderDescriptor name={name} email={email} scope={scope} />
    </components.Option>
  )
}

const EmailSenderSingleValue = ({ children, ...props }) => {
  const {
    data: { name, email, scope },
  } = props
  return (
    <components.SingleValue {...props}>
      <EmailSenderDescriptor name={name} email={email} scope={scope} />
    </components.SingleValue>
  )
}

const EmailFrom = () => {
  const act = useAct()

  const [addSenderModalVisible, setAddSenderModalVisible] = useState(false)

  const {
    id,
    emailBlast: {
      replyTo,
      senderName,
      emailSenderId,
      emailSender: { name, email, scope } = {},
    },
  } = useSel((s) => s.campaign)

  const complete = emailSenderId || name
  const enableReplyTo = window.ENV.SETTINGS.enable_reply_to && replyTo !== '{sender}' // {sender} = when a sender is selected, populate with the email address
  const [showReplyToError, setShowReplyToError] = useState(false)
  const [showSenderNameError, setShowSenderNameError] = useState(false)
  const [replyToValue, setReplyToValue] = useState(replyTo)
  const [senderNameValue, setSenderNameValue] = useState(senderName)

  const updateSender = (value, objs) => {
    let params = {
      emailSenderId: value,
      emailSender: { email: objs.email, scope: objs.scope, name: objs.name },
    }
    if (window.ENV.SETTINGS.enable_reply_to === "sender") {
      params.replyTo = objs.email
      setReplyToValue(objs.email)
    }
    if (!window.ENV.SETTINGS.enable_reply_to) {
      params.replyTo = ""
      setReplyToValue("")
    }
    act.campaign.upsertEmailBlast(params)
  }

  const createNew = () => {
    setAddSenderModalVisible(true)
  }

  const emailSenderValue = {
    id: emailSenderId,
    label: name,
    email,
    name,
    scope,
  }

  const afterCreateEmailSender = ({ id }) => {
    act.campaign.upsertEmailBlast({ emailSenderId: id })
    return act.campaign.persist().then(() => {
      act.campaignBuilder.closeEditor()
      setAddSenderModalVisible(false)
      onCancel()
    })
  }

  const editSenderName = (e) => {
    e.preventDefault()
    act.campaign.upsertEmailBlast({ senderName: name })
  }

  const updateSenderName = (e) => {
    // sender name must bee greated than 1 character
    setSenderNameValue(e.target.value)
    if (e.target.value.length > 1) {
      act.campaign.upsertEmailBlast({ senderName: e.target.value })
      setShowSenderNameError(false)
    } else {
      setShowSenderNameError(true)
    }
  }

  const updateReplyTo = (e) => {
    const email = e.target.value
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    setReplyToValue(email)
    if (emailRegex.test(email)) {
      act.campaign.upsertEmailBlast({ replyTo: email })
      setShowReplyToError(false)
    } else {
      setShowReplyToError(true)
    }
  }

  const valueObject = {
    value: emailSenderId,
    label: name,
    name: name,
    email: email,
    scope: scope,
  }

  return (
    <Step
      name="From"
      editor="from"
      complete={complete}
      canClickFinish={!showReplyToError && !showSenderNameError}
      shortDescriptor={
        <EmailFromShortDescriptor
          complete={complete}
          senderName={senderName || name}
          senderEmail={email}
        />
      }
    >
      <p>Who does the email come from?</p>

      <div className="field">
        <label>Sender name / email</label>
        <AsyncSelect
          entity="email_sender"
          valueObject={valueObject}
          loadStaticList={true}
          handleCreateNew={createNew}
          createNewText="Create new sender"
          components={{
            Option: EmailSenderOption,
            SingleValue: EmailSenderSingleValue,
            MenuList: MenuListWithFooter,
          }}
          onChange={updateSender}
          classNamePrefix="email-sender-select"
        />
      </div>

      {enableReplyTo && (
        <div className="field">
          <label>People will reply to</label>
          <input type="text" value={replyToValue} onChange={updateReplyTo} />
          {showReplyToError && (
            <p className="error">This email address is invalid</p>
          )}
        </div>
      )}

      <AddSenderModal
        open={addSenderModalVisible}
        onCancel={() => setAddSenderModalVisible(false)}
        afterCreate={afterCreateEmailSender}
      />
      <div className="field">
        {name && senderName === null && (
          <a href="#" onClick={editSenderName}>
            Change sender name
          </a>
        )}
        {name && senderName !== null && (
          <>
            <label>Sender Name</label>
            <input
              type="text"
              name="senderName"
              id="senderName"
              value={senderNameValue}
              onChange={updateSenderName}
            />
            {showSenderNameError && (
              <p className="error">Please enter a sender name</p>
            )}
          </>
        )}
      </div>
    </Step>
  )
}

export default EmailFrom
