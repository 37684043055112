// first find the one where isStart = true and then follow the nextActionId, and then any not included, put at end

export default function orderActions(allActions) {
  const actions = allActions.filter(a => a.active)

  const startAction = actions.find(a => a.isStart)
  if (!startAction) return actions

  const ordered = [startAction]
  let nextAction = actions.find(a => a.id === startAction.nextActionId)
  while (nextAction) {
    ordered.push(nextAction)
    nextAction = actions.find(a => a.id === nextAction.nextActionId)
  }

  const notIncluded = actions.filter(a => !ordered.find(o => o.id === a.id))
  ordered.push(...notIncluded)

  return ordered
}
