import { useAct } from 'FlowTaker/store'
import TakeSurveyForm from 'FlowBuilder/components/ActionViews/Survey/TakeSurveyForm'
import TemplateRender from 'FlowBuilder/components/ActionViews/TemplateRender'

export default function SurveyAction({ actionId, surveyId }) {
  const act = useAct()
  const bodyHtml = useSel(s => s.taker.flow.template.body)
  const context = useSel(s => s.taker.context)
  const currentPageNumber = useSel(s => s.taker.flowTaker.currentPageNumber)

  const handleSubmit = (responses) => {
    return act.taker.submitPage({ pageNumber: currentPageNumber, actionId, responses })
  }

  const onUpdateNoTarget = () => {
    return act.taker.updateNoTarget()
  }

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <TakeSurveyForm surveyId={surveyId} onSubmit={handleSubmit} pageNumber={currentPageNumber} onUpdateNoTarget={onUpdateNoTarget} />
    </>,
  }), [surveyId, currentPageNumber])

  return <div className='template-body'>
    <TemplateRender htmlContent={bodyHtml} componentMap={componentMap} context={context} />
  </div>
}
