export default function Date({ response, setResponse, name, disabled = false }) {
  const handleChange = (event) => {
    setResponse(event.target.value);
  }

  return (
    <div className="answers grow">
      <input
        type="date"
        onChange={handleChange}
        disabled={disabled}
        value={response || ''}
      />
    </div>
  )
}

