import { ChromePicker } from 'react-color';
import { Popover } from 'react-tiny-popover'
import './ColorField.scoped.scss'

const ColorField = ({color, onChange, setIsDropdownOpen, label = 'Colour'}) => {
  const [pickerVisible, setPickerVisible] = useState(false)
  const swatchRef = useRef(null)

  return (
    <div className="field color-field">
      <label>{label}</label>
      <div>
        <Popover
          isOpen={pickerVisible}
          positions={['bottom', 'top', 'right', 'left']}
          padding={10}
          onClickOutside={() => {
            setPickerVisible(false)
            setIsDropdownOpen?.(false) || (() => { })
          }}
          containerStyle={{ zIndex: 1010 }}
          content={
            <div style={{ display: 'inline-block' }}>
              <ChromePicker 
                disableAlpha 
                color={color} 
                onChange={(col) => onChange(col.hex)} 
              />
            </div>
          }
        >
          <span
            ref={swatchRef}
            className="color-field-swatch"
            style={{ backgroundColor: color }}
            onClick={() => {
              setPickerVisible(!pickerVisible)
              setIsDropdownOpen?.(true) || (() => { })
            }}
          >
            &nbsp;
          </span>
        </Popover>
        <input
          type="text"
          className="color-field"
          value={color}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  )
}

export default ColorField
