import 'stylesheets/admin'
require('datatables.net')(window, $)
require('datatables.net-dt')(window, $)
require('datatables.net-responsive')(window, $)

import Rails from '@rails/ujs'

if (window.newrelic) {
  // NR is added globally by the newrelic_rpm gem
  console.log('NewRelic detected, adding custom attributes')
  const screenWidth = window.screen.width
  const screenHeight = window.screen.height

  const screenResolution = `${screenWidth}x${screenHeight}`

  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight
  const viewportResolution = `${viewportWidth}x${viewportHeight}`

  newrelic.setCustomAttribute('clientId', window.ENV.CLIENT_ID)
  newrelic.setCustomAttribute('clientName', window.ENV.CLIENT_NAME)
  newrelic.setCustomAttribute('userId', window.ENV.USER_ID)
  newrelic.setCustomAttribute('userName', window.ENV.USER_NAME || '')
  newrelic.setCustomAttribute('hotjarSessionId', window.ENV.REQUEST_ID)
  newrelic.setCustomAttribute('screenWidth', screenWidth)
  newrelic.setCustomAttribute('screenHeight', screenHeight)
  newrelic.setCustomAttribute('screenResolution', screenResolution)
  newrelic.setCustomAttribute('viewportWidth', viewportWidth)
  newrelic.setCustomAttribute('viewportHeight', viewportHeight)
  newrelic.setCustomAttribute('viewportResolution', viewportResolution)
}

Rails.start()

$(document).ready(function () {
  setTimeout(() => {
    $('.alert').fadeOut()
  }, 4000)

  $('.js-open-modal').on('click', function (e) {
    e.preventDefault()
    var modalId = $(this).data('js-modal')
    $.each($(this).data(), function (i, v) {
      if (i.startsWith('attribute')) {
        var attribute = i.split('attribute')[1]
        $(`#${modalId} input[name="${attribute}"]`).val(v)
      }
    })
    $(`#${modalId}`).addClass('visible')
  })

  $('.js-close-modal').on('click', function (e) {
    e.preventDefault()
    var modalId = $(this).data('js-modal')
    $(`#${modalId}`).removeClass('visible')
  })

  $('form[data-confirm-submit]').on('submit', function (e) {
    e.stopImmediatePropagation()
    if (!confirm($(e.target).data('confirm-submit'))) {
      e.preventDefault()
    }
  })

  $('.js-mobile-menu').on('click', function () {
    $('.mobile-menu-overlay').show().addClass('visible')
  })

  $('.js-close-mobile-menu').on('click', function () {
    $('.mobile-menu-overlay').hide().removeClass('visible')
  })
})

import Table from '../bundles/Shared/components/DataTable/Table'
$(document).ready(function () {
  const node = document.getElementById('datatable-container')
  if (node) {
    const table = node.dataset.table
    ReactDOM.render(<Table table={table} />, node)
  }
})

$(document).ready(function () {
  $('form[data-confirm]').submit(function (e) {
    var $form = $(this)
    var message = $form.data('confirm')
    if (!confirm(message)) {
      e.preventDefault()
    }
  })

  document.querySelector('.sidebar-tab')?.addEventListener('click', function (e) {
    e.preventDefault()
    localStorage.setItem(
      'focus-mode',
      localStorage.getItem('focus-mode') === 'true' ? 'false' : 'true'
    )
    document.body.classList.toggle(
      'focus-mode',
      localStorage.getItem('focus-mode') === 'true'
    )
  })
  document.body.classList.toggle(
    'focus-mode',
    localStorage.getItem('focus-mode') === 'true'
  )
})

import AjaxNumber from '../bundles/Shared/components/AjaxNumber'
bindToDom('AjaxNumber', AjaxNumber)
import Analytics from 'Analytics/components/Analytics'
bindToDom('Analytics', Analytics)
import AudienceActions from '../bundles/AudienceActions/components/AudienceActions.jsx'
bindToDom('AudienceActions', AudienceActions)
import BuildSyncedAudience from 'Advertising/components/BuildSyncedAudience'
bindToDom('BuildSyncedAudience', BuildSyncedAudience)
import CampaignActions from 'Campaigns/ManageCampaign/components/Actions.jsx'
bindToDom('CampaignActions', CampaignActions)
import CampaignApproval from 'Campaigns/CampaignApproval/components/CampaignApproval'
bindToDom('CampaignApproval', CampaignApproval)
import CampaignBuilder from 'CampaignBuilder/components/CampaignBuilder'
bindToDom('CampaignBuilder', CampaignBuilder)
import CampaignOverview from '../bundles/CampaignOverview/components/CampaignOverview'
bindToDom('CampaignOverview', CampaignOverview)
import CampaignVolunteer from '../bundles/CampaignVolunteer/components/CampaignVolunteer'
bindToDom('CampaignVolunteer', CampaignVolunteer)
import CampaignsDatatable from 'CampaignsDatatable/components/CampaignsDatatable'
bindToDom('CampaignsDatatable', CampaignsDatatable)
import ClientSwitcher from 'Interface/ClientSwitcher/components/ClientSwitcher'
bindToDom('ClientSwitcher', ClientSwitcher)
import Compliance from 'Compliance/components/Compliance'
bindToDom('Compliance', Compliance)
import CreateCampaign from '../bundles/Campaigns/CreateCampaign/components/CreateCampaign'
bindToDom('CreateCampaign', CreateCampaign)
import Dashboard from '../bundles/Dashboard/components/Dashboard'
bindToDom('Dashboard', Dashboard)
require('datatables.net')(window, $)
require('datatables.net-responsive')(window, $)
import DesktopCaller from '../bundles/DesktopCaller/components/DesktopCaller'
bindToDom('DesktopCaller', DesktopCaller)
import DesktopMessager from '../bundles/DesktopMessager/components/DesktopMessager'
bindToDom('DesktopMessager', DesktopMessager)
import EditUser from 'Users/EditUser/components/EditUser'
bindToDom('EditUser', EditUser)
import EditableName from '../bundles/Shared/components/EditableName/EditableName'
bindToDom('EditableName', EditableName)
import Efforts from 'Efforts/components/Efforts'
bindToDom('Efforts', Efforts)
import EmailBuilder from 'EmailBuilder/components/EmailBuilder'
bindToDom('EmailBuilder', EmailBuilder)
import TargetPreferences from 'TargetPreferences/components/TargetPreferences'
bindToDom('TargetPreferences', TargetPreferences)

window.postFeedback = function () {
  axios.post(window.location.href).catch(() => {
    document.write('Sorry something went wrong')
  })
}
import FlowBuilder from 'FlowBuilder/components/FlowBuilder'
bindToDom('FlowBuilder', FlowBuilder)
import FlowTaker from 'FlowTaker/components/FlowTaker'
bindToDom('FlowTaker', FlowTaker)
import FlowWizard from 'FlowWizard/components/FlowWizard'
bindToDom('FlowWizard', FlowWizard)

import Help from 'Interface/Help/components/Help'
bindToDom('Help', Help)
import Leaderboard from '../bundles/Shared/components/Leaderboard/index'
bindToDom('Leaderboard', Leaderboard)
import ManageActionsWrapped from 'Shared/components/ManageMenu/ManageActionsWrapped'
bindToDom('ManageActions', ManageActionsWrapped)
import ManageBilling from 'ManageBilling/components/ManageBilling'
bindToDom('ManageBilling', ManageBilling)
import ManageCustomFields from 'Settings/ManageCustomFields/components/ManageCustomFields'

bindToDom('ManageCustomFields', ManageCustomFields)
import ManageEmailSenders from 'Settings/ManageEmailSenders/components/ManageEmailSenders'
bindToDom('ManageEmailSenders', ManageEmailSenders)
import ManageEmailTemplates from 'Settings/ManageEmailTemplates/components/ManageEmailTemplates'
bindToDom('ManageEmailTemplates', ManageEmailTemplates)
import ManageFlowTemplates from 'Settings/ManageFlowTemplates/components/ManageFlowTemplates'
bindToDom('ManageFlowTemplates', ManageFlowTemplates)
import ManageGroup from 'Users/ManageGroup/components/ManageGroup'
bindToDom('ManageGroup', ManageGroup)
import ManageLists from 'Settings/ManageLists/ManageLists'

bindToDom('ManageLists', ManageLists)
import ManageRoles from 'Settings/ManageRoles/components/ManageRoles'
bindToDom('ManageRoles', ManageRoles)
import ManageUsers from 'Users/ManageUsers/components/ManageUsers'
bindToDom('ManageUsers', ManageUsers)
import OverviewStats from '../bundles/CampaignStats/components/OverviewStats.jsx'
bindToDom('OverviewStats', OverviewStats)
import QuickSearches from '../bundles/QuickSearches/components/QuickSearches'
import store from 'Shared/store'
import { Provider } from 'react-redux'

const QuickSearchesWithProvider = (props) => (
  <Provider store={store}>
    <QuickSearches {...props} />
  </Provider>
)

bindToDom('QuickSearches', QuickSearchesWithProvider)
import Reporting from 'Reporting/components/Reporting'
bindToDom('Reporting', Reporting)
import SearchBuilder from 'SearchBuilder/components/SearchBuilder'
bindToDom('SearchBuilder', SearchBuilder)
import { Rules } from 'SearchBuilder/components/Description/Rules'
bindToDom('SearchDescription', Rules)
import Search from 'Searches/components/Search.jsx'
bindToDom('Search', Search)
import SendProgress from '../bundles/SendBlast/components/SendProgress'
bindToDom('SendProgress', SendProgress)
import SendBlast from 'SendBlast/components/SendBlast'
bindToDom('SendBlast', SendBlast)
import Spotlight from 'Interface/Spotlight/components/Spotlight'
bindToDom('Spotlight', Spotlight)

import SurveyBuilder from 'SurveyBuilder/components/SurveyBuilder'
bindToDom('SurveyBuilder', SurveyBuilder)
import SurveyOverview from '../bundles/SurveyOverview/components/SurveyOverview.jsx'
bindToDom('SurveyOverview', SurveyOverview)
import Survey from '../bundles/Survey/components/Survey'
bindToDom('Survey', Survey)
import SyncedAudienceShow from 'Advertising/components/SyncedAudienceShow'
bindToDom('SyncedAudienceShow', SyncedAudienceShow)
bindToDom('Table', Table, false)
import TargetDetailsUpdate from 'TargetDetailsUpdate/components/TargetDetailsUpdate'
bindToDom('TargetDetailsUpdate', TargetDetailsUpdate)
import TargetDetailsUpdatesActions from '../bundles/TargetDetailsUpdatesActions/components/TargetDetailsUpdatesActions.jsx'
bindToDom('TargetDetailsUpdatesActions', TargetDetailsUpdatesActions)
import TargetProfile from 'TargetProfile'
bindToDom('TargetProfile', TargetProfile)

import Tutorial from 'Interface/Tutorial/components/Tutorial'
bindToDom('Tutorial', Tutorial)
// Modified from https://codepen.io/claudiabdm/pen/XWgrqGr

function TwoFactor(wrapper, input, totalDigits) {
  this.wrapper = wrapper
  this.totalDigits = totalDigits
  this.form = null
  this.inputs = []
  this.input = input
}
window.TwoFactor = TwoFactor

TwoFactor.prototype.validateNumericInputs = function validateNumericInputs(input) {
  if (isNaN(Number(input.value)) || input.value === '') {
    input.classList.add('invalid')
    return false
  }
  input.classList.remove('invalid')
  return input.value.length > 1 ? input.value[0] : input.value
}
TwoFactor.prototype.focusElement = function focusElement(elem) {
  elem.focus()
  elem.select()
}
TwoFactor.prototype.getCode = function getCode() {
  return this.inputs.map((input) => input.value).join('')
}
TwoFactor.prototype.render = function () {
  this.wrapper.appendChild(createInputsWrapper.call(this, this.totalDigits))

  addEvents.call(this)

  function createInput(idx) {
    const input = document.createElement('input')
    input.classList.add('code__digit')
    input.type = 'number'
    input.maxlength = '1'
    input.ariaLabel = `Digit ${idx}`
    input.ariaRequired = 'true'
    input.dataset.idx = idx
    input.autocomplete = 'one-time-code'
    return input
  }

  function createInputsWrapper(totalDigits) {
    const digitsWrapper = document.createElement('div')
    const fragment = document.createDocumentFragment()
    for (let i = 0; i < this.totalDigits; i++) {
      const input = createInput(i)
      this.inputs.push(input)
      fragment.appendChild(input)
    }
    digitsWrapper.classList.add('code__digits')

    digitsWrapper.style.setProperty('--total-digits', totalDigits)
    digitsWrapper.appendChild(fragment)
    return digitsWrapper
  }

  function addEvents() {
    this.wrapper.addEventListener('input', checkInput(this))
    this.wrapper.addEventListener('click', focusInput(this))
    this.wrapper.addEventListener('paste', pasteCode(this))
    this.wrapper.addEventListener('keydown', pressKey(this))

    function checkInput(thisTwoFactor) {
      return function handleNumericInput(e) {
        const value = thisTwoFactor.validateNumericInputs(e.target)
        if (value !== false) {
          e.target.value = value
          focusNextInput.call(thisTwoFactor, e.target)
        } else {
          e.target.value = ''
        }
        thisTwoFactor.input.value = thisTwoFactor.getCode()
      }
    }

    function focusInput(thisTwoFactor) {
      return function handleClickInput(e) {
        if (e.target.tagName == 'INPUT') {
          thisTwoFactor.focusElement(e.target)
        }
      }
    }

    function pasteCode(thisTwoFactor) {
      return function handleCopypase(e) {
        e.preventDefault() // you can copy code in any input
        const copyCode = e.clipboardData.getData('text')
        for (let i = 0; i < thisTwoFactor.inputs.length; i++) {
          thisTwoFactor.inputs[i].value = copyCode[i]
          thisTwoFactor.inputs[i].focus()
        }
        setTimeout(() => {
          for (let input of thisTwoFactor.inputs) {
            if (thisTwoFactor.validateNumericInputs(input) === false) {
              thisTwoFactor.focusElement(input)
              break
            }
          }
        }, 0)
        thisTwoFactor.input.value = thisTwoFactor.getCode()
      }
    }

    function pressKey(thisTwoFactor) {
      return function handleEventKeys(e) {
        handleArrowKeys(e, thisTwoFactor)
        handleDelete(e, thisTwoFactor)
      }
    }

    function handleArrowKeys(e, thisTwoFactor) {
      if (e.key == 'ArrowRight') {
        e.preventDefault()
        focusNextInput.call(thisTwoFactor, e.target)
      }
      if (e.key == 'ArrowLeft') {
        e.preventDefault()
        focusPrevInput.call(thisTwoFactor, e.target)
      }
    }

    function handleDelete(e, thisTwoFactor) {
      if (e.key == 'Backspace') {
        e.target.value = ''
        e.target.classList.add('invalid')
        focusPrevInput.call(thisTwoFactor, e.target)
      }
    }
  }

  function focusNextInput(currentInput) {
    if (currentInput.nextElementSibling) {
      this.focusElement(currentInput.nextElementSibling)
    }
  }

  function focusPrevInput(currentInput) {
    if (currentInput.previousElementSibling) {
      this.focusElement(currentInput.previousElementSibling)
    }
  }

  this.focusElement(this.wrapper.querySelector('input:first-child'))
}

import UploadAudience from '../bundles/UploadAudience/components/UploadAudience.jsx'
bindToDom('UploadAudience', UploadAudience)
import UserInfo from 'Interface/UserInfo/components/UserInfo'

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('user-info')

  if (node) {
    const initial = node.getAttribute('data-initial')
    const name = node.getAttribute('data-name')
    const organisation = node.getAttribute('data-organisation')
    const logoutLink = node.getAttribute('data-logout-link')
    const profilePicUrl = node.getAttribute('data-profile-pic-url')

    ReactDOM.render(
      <UserInfo
        initial={initial}
        name={name}
        organisation={organisation}
        logoutLink={logoutLink}
        profilePicUrl={profilePicUrl}
      />,
      node
    )
  }
})
import 'stylesheets/user'
import Actions from '../bundles/Users/Actions/components'
bindToDom('UsersActions', Actions)
import WorkflowBuilder from 'Workflows/components/WorkflowBuilder'
bindToDom('WorkflowBuilder', WorkflowBuilder)
import WorkflowOverview from 'Workflows/components/WorkflowOverview'
bindToDom('WorkflowOverview', WorkflowOverview)
import FlowOverview from 'FlowOverview/components/FlowOverview'
bindToDom('FlowOverview', FlowOverview)
import FlowTemplateBuilder from 'FlowTemplateBuilder/components/FlowTemplateBuilder'
bindToDom('FlowTemplateBuilder', FlowTemplateBuilder)