/* eslint-disable react/prop-types */
import { FC, ReactChildren, useEffect } from 'react'
import Button from '../Button'
import PillEditor from './PillEditor'
import {
  EditorState,
  Modifier,
  RichUtils,
  CompositeDecorator,
  ContentState,
} from 'draft-js'
import ColorField from 'Shared/components/ColorField/ColorField'
import './EditLink.scoped.scss'

const Link: FC<{
  contentState: ContentState
  entityKey: string
  children: ReactChildren
}> = ({ contentState, entityKey, children }) => {
  const { url, color } = contentState.getEntity(entityKey).getData()
  const styles = { color }
  return <a href={url} style={styles}>{children}</a>
}

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

const createLinkDecorator = () =>
  new CompositeDecorator([{ strategy: findLinkEntities, component: Link }])

const getLinkData = (editorState) => {
  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  const blockKey = selection.getStartKey()
  const block = contentState.getBlockForKey(blockKey)
  const offset = selection.getStartOffset()
  const entityKey = block.getEntityAt(offset + 1)

  let linkURL
  let linkColor
  if (entityKey) {
    const entity = contentState.getEntity(entityKey)
    linkURL = entity.getData().url
    linkColor = entity.getData().color
  } else {
    linkURL = ''
    linkColor = ''
  }

  const start = selection.getStartOffset()
  const end = selection.getEndOffset()
  const linkText = block.getText().slice(start, end)
  return { linkText, linkURL, linkColor }
}

const setLink = (editorState: EditorState, url, text, color) => {
  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  const blockKey = selection.getStartKey()
  const block = contentState.getBlockForKey(blockKey)
  const offset = selection.getStartOffset()
  const entityKey = block.getEntityAt(offset + 1)

  if (entityKey) {
    // Update existing link
    const updatedURL = contentState.replaceEntityData(entityKey, { url, color })
    const updatedText = Modifier.replaceText(
      updatedURL,
      selection,
      text,
      editorState.getCurrentInlineStyle(),
      entityKey
    )
    return EditorState.push(editorState, updatedText, 'change-block-data')
  } else {
    // Create a new link

    if (selection.isCollapsed()) {
      // ...with no selected text

      contentState.createEntity('LINK', 'MUTABLE', { url, color })
      const entityKey = contentState.getLastCreatedEntityKey()

      const textWithEntity = Modifier.insertText(
        contentState,
        selection,
        text,
        null,
        entityKey
      )
      return EditorState.createWithContent(textWithEntity, createLinkDecorator())
    } else {
      // ...with selected text

      const updatedText = Modifier.replaceText(
        contentState,
        selection,
        text,
        editorState.getCurrentInlineStyle()
      )

      // return EditorState.push(editorState, updatedText)

      const contentStateWithEntity = updatedText.createEntity('LINK', 'MUTABLE', { url, color })
      const entityKey = contentState.getLastCreatedEntityKey()

      const nextEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      })

      let newSelection = nextEditorState.getSelection()
      if (nextEditorState.getSelection().getIsBackward()) {
        newSelection = newSelection.merge({
          anchorOffset: text.length + newSelection.getFocusOffset(),
        })
      } else {
        newSelection = newSelection.merge({
          focusOffset: text.length + newSelection.getAnchorOffset(),
        })
      }

      return RichUtils.toggleLink(nextEditorState, newSelection, entityKey)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function EditLink({ mergeTags, visible, close, editorState, onSave, setIsDropdownOpen }) {
  const [url, setUrl] = useState(null)
  const [text, setText] = useState(null)
  const [color, setColor] = useState('')

  useEffect(() => {
    if (visible) {
      const { linkURL, linkText, linkColor } = getLinkData(editorState)
      setUrl(linkURL)
      setText(linkText)
      setColor(linkColor)
    }
  }, [visible])

  const save = (e) => {
    e.preventDefault()
    onSave(setLink(editorState, url, text, color))
    close()
  }

  // Wait until we have the link data before rendering so PillEditor populates correctly
  if (!visible || url === null) return null

  return (
    <div className="popup widest edit-link">
      <form onSubmit={save}>
        <div className="field">
          <label>URL</label>
          <PillEditor
            initialValue={url}
            onChange={setUrl}
            availableTags={mergeTags}
            singleLine
            condensed
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </div>
        <div className="field">
          <label>Link text</label>
          <PillEditor
            initialValue={text}
            onChange={setText}
            availableTags={mergeTags}
            singleLine
            condensed
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </div>
        <div className="field">
          <ColorField
            color={color}
            onChange={setColor}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </div>
        <div className="field">
          <Button primary>Save</Button>
        </div>
      </form>
    </div>
  )
}
