import { style } from "d3-selection"

const channelConfig = {
  whatsapp: {
    text: 'Share on WhatsApp',
    svg: <SvgIconsCircleWhatsApp />,
    getUrl: (publicUrl) => {
      return `https://wa.me/?text=${encodeURIComponent(publicUrl)}`
    },
    primaryBackground: '#25D366'
  },
  twitter: {
    text: 'Share on X',
    svg: <SvgIconsCircleX />,
    getUrl: (publicUrl) => {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(publicUrl)}`
    },
    primaryBackground: '#111'
  },
  facebook: {
    text: 'Share on Facebook',
    svg: <SvgIconsCircleFacebook />,
    getUrl: (publicUrl) => {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(publicUrl)}`
    },
    primaryBackground: '#0865FE'
  },
  linkedin: {
    text: 'Share on LinkedIn',
    svg: <SvgIconsCircleLinkedIn />,
    getUrl: (publicUrl) => {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(publicUrl)}`
    },
    primaryBackground: '#0072B1'
  },
  bluesky: {
    text: 'Share on Bluesky',
    svg: <SvgIconsCircleBluesky />,
    getUrl: (publicUrl) => {
      return `https://bsky.app/intent/compose?text=${encodeURIComponent(publicUrl)}`
    },
    primaryBackground: '#3BA0FE'
  }
}

export default function ShareForm({ action, onSubmit, publicUrl }) {
  const { config } = action
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    let timeoutId

    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false)
      }, 3000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [copied])

  const handleCopy = async () => {
    await navigator.clipboard.writeText(publicUrl)
    setCopied(true)
  }

  return <div className="share-form">
    <div className="share-button-container">
      { (config.channels || []).map((channel, index) => {
        const { text, svg, getUrl, primaryBackground = () => {} } = channelConfig[channel] || {}

        return (
        <div
          className={`add-padding ${index === 0 ? 'primary-share-link' : 'secondary-share-link'}`}
          style={index === 0 ? { backgroundColor: primaryBackground } : {}}
        >
          <a key={channel} className={`share-button flex align-center`} target="_blank" href={getUrl(publicUrl)}>{svg}{text}</a>
        </div>
        )
      }) }
    </div>

    <div className="margin-top share-link-container">
      <h4 className="larger">Share Link</h4>
      <div className="share-box flex align-center gap-8">
        <input value={publicUrl} onClick={handleCopy} readOnly />
        <SvgIconsCopy onClick={handleCopy} className="copy-icon" />
      </div>
      <p className="smaller lighter">{copied ? 'Copied!' : 'Copy this link to share'}</p>
      <a className='flex justify-center skip-button' onClick={onSubmit}>
        Skip this step
      </a>
    </div>
  </div>
}
