export const selectRootOrgRule = (rules) => 
  rules && rules.organisations.find(
    (o) => o.id === window.ENV.ORGANISATION_ID &&
      ['none', 'all'].includes(o.grantToSuborganisations)
  )

export const selectRootAndSubOrgRules = (rules) => {
  if(!rules) return []
  return rules.organisations.filter((o) => o.id === window.ENV.ORGANISATION_ID)
}

