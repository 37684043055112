import AsyncSelect from 'Shared/components/AsyncSelect'
import './MergeTagList.scoped.scss'

function MergeTag({ mergeTag, insert }) {
  return (
    <li
      onClick={(e) => {
        e.nativeEvent.stopImmediatePropagation();
        if (!mergeTag.disabled) {
          insert(mergeTag)
        }
      }}
      className={`${mergeTag.disabled ? 'disabled' : ''}`}
      title={`${mergeTag.disabled ? 'Contact support to enable' : ''}`}
    >
      <span className="merge-tag">{mergeTag.label}</span>
    </li>
  )
}

function buildMergeTag(mergeTag, parameters = {}) {
  if (!mergeTag.parameters) {
    return `{{${mergeTag.slug}}}`
  } else if (_.values(parameters).length === 0) { 
    return `{% ${mergeTag.slug} %}`
  } else {
    return `{% ${mergeTag.slug} ${_.map(parameters, (v, k) => v).join(' ')} %}`
  }

}

export default function MergeTagList({ insert, mergeTags, close, popup = true }) {
  const [filterCategory, setFilterCategory] = useState('All content')
  const [showAll, setShowAll] = useState(false)
  const [search, setSearch] = useState('')

  const [mergeTag, setMergeTag] = useState(null)
  const [paramsOpen, setParamsOpen] = useState(false)

  const mergeTagTests = useSel((s) => _.values(s.mergeTagTests?.entities || {}))

  const chooseTag = (mergeTag) => {
    if (mergeTag.parameters && _.keys(mergeTag.parameters).length > 0) {
      setMergeTag(mergeTag)
      setParamsOpen(true)
    } else {
      insert(buildMergeTag(mergeTag))
      close()
    }
  }

  const chooseTagWithParams = (mergeTag, parameters) => {
    insert(buildMergeTag(mergeTag, parameters))
    setMergeTag(null)
    setParamsOpen(false)
    close()
  }

  let tagList
  const allTags = Object.values(mergeTags).reduce((acc, val) => acc.concat(val || []), [])

  if (search !== '') {
    const searchTags = allTags.filter((tag) =>
      tag.label.toLowerCase().includes(search.toLowerCase())
    )

    tagList = (
      <div className="merge-tag-list">
        <div className="search-result-list">
          <div className="heading">Search results</div>
          <ul>
            {searchTags.map((mergeTag, index) => (
              <MergeTag key={index} mergeTag={mergeTag} insert={chooseTag} />
            ))}
          </ul>
        </div>
      </div>
    )
  } else {
    const baseTagContainer = { 'All content': allTags }
    if (mergeTagTests.length > 0) {
      baseTagContainer['Experiments'] = mergeTagTests.map((test) => ({
        label: test.name,
        slug: `tests.${test.slug}`,
        disabled: false,
      }))
    }
    mergeTags = Object.assign(baseTagContainer, mergeTags)
    let filteredTags = mergeTags[filterCategory] || [] // Handle when no tags present
    const showMoreLink = filteredTags.length > 15 && !showAll
    if (!showAll) filteredTags = filteredTags.slice(0, 15)

    tagList = (
      <div className="merge-tag-list">
        <div className="category-list">
          <div className="heading">Category</div>
          <ul>
            {Object.keys(mergeTags).map((category, index) => (
              <li
                key={index}
                className={category === filterCategory ? 'active' : null}
                onClick={function () {
                  setShowAll(false)
                  setParamsOpen(false)
                  setMergeTag(null)
                  return setFilterCategory(category)
                }}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        { paramsOpen && <ParamsForm mergeTag={mergeTag} insert={insert} close={close} /> }

        { !paramsOpen && <div className="tag-list">
          <div className="heading">Fields</div>
          <ul>
            {filteredTags.map((mergeTag, index) => (
              <MergeTag key={index} mergeTag={mergeTag} insert={chooseTag} />
            ))}
          </ul>
          {showMoreLink && (
            <div
              className="more"
              onClick={function (e) {
                e.nativeEvent.stopImmediatePropagation()
                return setShowAll(true)
              }}
            >
              More
            </div>
          )}
        </div> }
      </div>
    )
  }

  const classNames = ['search-wrapper']

  if(popup) {
    classNames.push('popup popup-list')
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="search">
        <input
          type="text"
          placeholder={'Search...'}
          onChange={function (e) {
            setShowAll(false)
            return setSearch(e.target.value || '')
          }}
        />
      </div>
      {tagList}
    </div>
  )
}

function ParamsForm({ mergeTag, close, insert }) {
  const [values, setValues] = useState({})
  const disabled = _.some(mergeTag.parameters, (param) => !values[param.slug])

  function onClick() {
    insert(buildMergeTag(mergeTag, values))
    close()
  }

  return <div className="margin-left half">
    <div>Add a {mergeTag.label} tag</div>
    { _.map(mergeTag.parameters, (param, k) => <Param key={k} param={param} values={values} setValues={setValues} />) }
    <div>
      <button className="button primary margin-top" onClick={onClick} disabled={disabled}>Insert</button>
    </div>
  </div>
}

function Param({ param, values, setValues }) {
  const { type, entity, dependsOn, multiple } = param

  const upsertValue = (value) => setValues(d => ({ ...d, [param.slug]: value }))
  const value = values[param.slug] || ''

  return <div className="row margin-top">
    <div className='column remaining'>
      <div className='field'>
        <label className="margin-bottom half">{_.startCase(param.label)}</label>
        {type === 'entity' && (
          <AsyncSelect
            entity={entity}
            value={value}
            onChange={upsertValue}
          />
        )}
      </div>
    </div>
  </div>
}
