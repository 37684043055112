import Step from './Step'
import EmailContentTabs from './EmailContentTabs'
import EntitySelector from 'Shared/components/EntitySelector/EntitySelector'
import SendSampleEmail from './SendSample/SendSampleEmail'
import EmailPreview from 'Shared/components/EmailPreview'
import ScheduleButton from 'CampaignBuilder/components/EditSchedule/components/ScheduleButton'
import getDescription from 'CampaignBuilder/components/EditSchedule/getDescription'
import useValidateCampaign from 'CampaignBuilder/hooks/useValidateCampaign'
import './EmailContent.scoped.scss'

export default function PreviewEmail() {
  const act = useAct()
  const {
    search = {},
    status,
    isWorkflowControlled,
    emailBlast = {},
  } = useSel((s) => s.campaign)
  const { scheduledFor, subjectLine, subjectLines, emailSenderId } = emailBlast
  const { id: searchId, name: searchName } = search
  const blockCount = useSel((s) => s.blocks.present.ids.length)
  const hasSubjectLine = !!subjectLine?.subjectLine || !!subjectLines[0]?.subjectLine
  const canSendSample = [emailSenderId, hasSubjectLine, !!blockCount].every(Boolean);
  const { previewTargetId, renderedBody, renderedSubjectLine } = useSel((s) => s.campaignBuilder)
  const [sendSampleModalOpen, setSendSampleModalOpen] = useState(false)
  const [complete, errors] = useValidateCampaign()
  const allowSchedule = !isWorkflowControlled
  const finishText = status === 'live' ? 'Save' : isWorkflowControlled ? 'Finish' : 'Send'

  function finish() {
    return act.campaign.persist().then(() => {
      if (!isWorkflowControlled) {
        act.sendBlast.open()
      } else {
        act.campaign.launch().then(({ data }) => {
          window.location = data.redirectUrl
        })
      }
    })
  }

  return (
    <Step
      name="Preview"
      editor="preview"
      shortDescriptor={
        <p className="incomplete no-margin-bottom">{getDescription(scheduledFor)}</p>
      }
      action={finish}
      staticOpenButton={'Preview'}
      finishButtonText={finishText}
      canClickFinish={complete}
      canOpen={true}
      validationErrors={errors}
      additionalButtons={allowSchedule && <ScheduleButton disabled={!complete} />}
      complete={!!scheduledFor}
    >
      <div className="row">
        <p className="dark-grey">
          Here you can look up the name of any contact in your audience and see how the email would look with their details in the preview.
        </p>
      </div>
      <div className='row'>
        <EmailContentTabs />
      </div>
      <div className="row">
        <div className="column half">
          <EntitySelector
            entity="target"
            scope={searchId}
            value={previewTargetId}
            onChange={(id) => act.campaignBuilder.upsert({ previewTargetId: id })}
            placeholder="Start typing a target's name"
            defaultSearch=""
          />
        </div>
        <div className="column half">
          <div className="preview-container">
            {renderedBody && (
              <EmailPreview
                html={renderedBody}
                subjectLine={renderedSubjectLine}
                senderName={emailBlast?.emailSender?.name}
                searchName={searchName}
                iframeStyle={{ transform: 'scale(0.7)', width: '100%' }}
                fromPreview={true}
              />
            )}
          </div>
          <a
            className={`margin-top blue-link smaller text-center full-width underlined ${
              !canSendSample ? 'disabled' : ''
            }`}
            onClick={() => (canSendSample ? setSendSampleModalOpen(true) : null)}
          >
            Send a sample email to an email you provide
          </a>
          {!canSendSample && (
            <p className="error smaller text-center no-margin-bottom">
              You need to finish your email to send a preview.
            </p>
          )}
          <SendSampleEmail
            open={sendSampleModalOpen}
            renderedBody={renderedBody}
            close={() => setSendSampleModalOpen(false)}
          />
        </div>
      </div>
    </Step>
  )
}
