import { createSlice } from '@reduxjs/toolkit'

const reducer = createSlice({
  name: 'local',
  initialState: {
    refreshKey: 0,
    navItem: 'preview',
    selectedActionId: null,
    selectedQuestionId: null,
    selectedPageNumber: 1,
    modal: {
      kind: null
    },
    isDirty: false,
    errors: {},
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
    // Clear a single key from local.errors
    clearErrors: (s, a) => {
      delete s.errors[a.payload]
    }
  },
}).reducer

const actions = (dis, store, restClient) => {
  return {
    set: (payload) => {
      dis({ type: 'local/set', payload })
    },
    upsert: (payload) => {
      dis({ type: 'local/upsert', payload })
    },
    setNav: (navItem) => {
      dis({ type: 'local/upsert', payload: { navItem: navItem } })
    },
    setModal: (modal) => {
      dis({ type: 'local/upsert', payload: { modal } })
    },
    setDirty: (isDirty) => {
      dis({ type: 'local/upsert', payload: { isDirty } })
    },
    closeModal: () => {
      dis({ type: 'local/upsert', payload: { modal: { kind: null } } })
    },
  }
}

export { reducer, actions }
export default { reducer, actions }
