import { useStore } from 'react-redux'
import restClient from 'Shared/hooks/restClient'
import access from './access'

const actionSlices = { access }

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}
