import TemplateRender from '../TemplateRender'
import Preview from '../Preview'
import FieldEdit from '../FieldEdit'
import LundaForm from './LundaForm'

export default function LundaActionView({ actionId }) {
  const templateFields = useSel(s => s.builder.flow.templateFields)
  const flowFields = useSel(s => s.builder.flow.flowFields)
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const config = useSel(s => s.actions.entities[selectedActionId]?.config || {})
  const context = { template: templateFields, embedUrl: config?.embedUrl, flow: { ...flowFields, currentActionKind: 'lunda' } }
  const action = useSel(s => s.actions.entities[actionId] || {})

  const { body, head, styles } = useSel(s => s.builder.flow.template)

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
    <LundaForm
      action={action}
      onSubmit={() => Promise.resolve()}
    />
    </>,
    FieldEdit,
  }), [JSON.stringify(config)])

  return <>
    <Preview styles={styles} head={head}>
      <TemplateRender htmlContent={body} componentMap={componentMap} context={context} edit />
    </Preview>
  </>
}
