import isEditing from './isEditing'

export default function buildSteps(campaignType, status, blastType, isWorkflowControlled = false) {
    const types = {
      phone: [
        'effort',
        'audience',
        'phoneFrom',
        'instructions', 
        'script',
        'users',
        'settings',
        'previewScript'
      ],
      sms_blast: [
        'effort',
        'audience',
        'from',
        'content',
        'settings',
        'previewSms'
      ],
      sms_survey: [
        'effort',
        'audience',
        blastType === 'whatsapp' ? 'whatsAppContent' : null,
        'script',
        'settings',
        'previewSmsSurvey'
      ],
      sms_conversations: [
        'effort',
        'audience',
        'instructions',
        'users',
        'content',
        'settings',
        'cannedResponses',
        'previewSms'
      ],
      email: [
        'effort',
        'audience',
        'emailFrom',
        'emailSubject',
        'emailContent',
        'settings',
        'previewEmail'
      ]
    }

    if (campaignType.startsWith('sms_')) {
      if (blastType === 'whatsapp') {
        types[campaignType] = types[campaignType].filter((step) => step !== 'from')
        types[campaignType] = types[campaignType].map((step) =>
          step === 'content' ? 'whatsAppContent' : step
        )
      } else if (blastType === 'sms') {
        // No changes are needed for 'sms' blastType based on the requirements
      }
    }

    if (isEditing(campaignType, status)) {
      types[campaignType] = types[campaignType].filter(function (step) {
        if (_.includes(['phone', 'sms_conversations', 'sms_survey'], campaignType) && _.includes(['instructions', 'script'], step))
          return true

        if(campaignType === 'phone' && step === 'phoneFrom') return true

        if ((campaignType === 'phone' || campaignType === 'sms_conversations'))
          return _.includes(['users', 'settings', 'cannedResponses'], step)
      })
    }

    // Remove 'audience' and 'settings' steps if workflow is controlled.
    if (isWorkflowControlled) {
      return _.compact(types[campaignType]).filter(step => !['audience', 'settings'].includes(step));
    }

    return _.compact(types[campaignType])
  }
