import { Backdrop } from '@material-ui/core'
import { Content, Root, Title } from './styled'
import './BackdropModal.scoped.scss'

const BackdropModal = ({
  open,
  title,
  newTitle = false,
  onCancel,
  children,
  maxWidth = 'md',
  tall = false,
  noContentWrapper = false,
  scrollable = true,
  hideClose = false,
}) => {

  return (
    <Backdrop open={open} style={{ zIndex: 1000 }}>
      <Root maxWidth={maxWidth} className={`modal-root ${tall ? 'tall' : ''}`}>
        <Title>
          {newTitle ? <h2 className="title-bigger">{title}</h2> : <h1>{title}</h1>}
          {!hideClose && (
            <button className="button naked close-modal" onClick={onCancel}>
              <SvgIconsClose className="no-margin" />
            </button>
          )}
        </Title>
        {noContentWrapper ? (
          <div className={scrollable ? 'modal-content scrollable' : 'modal-content'}>
            {children}
          </div>
        ) : (
          <Content className={scrollable ? 'scrollable no-wrap' : 'no-wrap'}>
            {children}
          </Content>
        )}
      </Root>
    </Backdrop>
  )
}

export default BackdropModal
