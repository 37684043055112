const columns = [
  {
    data: 'slug',
    className: 'bold',
    width: '70%',
    render: (data, type, row, meta) =>
      `<a href='/admin/settings/flow_templates/${row['id']}/edit'>${data}</a>`,
  },
  {
    data: 'in_use',
    width: '30%',
    render: (data, type, row, meta) =>
      data == 'true' ? 'In Use' : 'Not In Use',
  }
]

const headers = ['Slug', 'In Use?']

const filters = []

const sorters = [
  { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' },
]

export { columns, headers, filters, sorters }
