import { useAct } from 'FlowBuilder/store'

export default function LundaActionForm({ actionId }) {
    const act = useAct()

    const action = useSel(s => s.actions.entities[s.local.selectedActionId])
    const config = action.config || { embedUrl: '' }

    function update(data) {
        act.actions.upsert(actionId, { config: { ...config, ...data } })
    }

    return <div className="box narrow">
        <h2 className='no-margin-top'>Lunda donation form</h2>
        <div className='field'>
            <label className="bold">Embed URL</label>
            <input type='text' className='full-width margin-top' value={config.embedUrl} onChange={e => update({ embedUrl: e.target.value })} />
        </div>
    </div>
}

