import './Checkbox.scoped.scss'

export default function Checkbox({ label, checked = false, className = "", card = false, ...rest }) {
  const classNames = (className + " flex items-center gap-10").split(' ')

  if(!classNames.includes("no-margin-bottom")) {
    classNames.push("margin-bottom")
  }

  if (card) {
    classNames.push('checkbox-card')
  }

  if (checked) {
    classNames.push('checkbox-card-checked')
  }

  return <label className={classNames.join(' ')}>
    <input type="checkbox" checked={checked} {...rest} />
    <span>{label}</span>
  </label>
}

