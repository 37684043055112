const access = (dis, store, client) => {
  function load(entityType, entityId) {
    return client
      .get(`/api/access/${entityType}/${entityId}`)
      .then((response) => {
        const { description, ...rest } = response.data
        dis({ type: 'access/setDescription', payload: description })
        dis({ type: 'access/updateRules', payload: rest })
        dis({ type: 'access/setLoading', payload: false })
        return response.data
      })
      .catch((error) => {
        dis({ type: 'access/setLoading', payload: false })
        dis({ type: 'access/setError', payload: 'Failed to load access settings' })
      })
  }

  function persist(entityType, entityId) {
    let rules = store.getState().access.rules

    return client
      .post(`/api/access/${entityType}/${entityId}`, rules)
      .then((response) => {
        updateRules(response.data)
        dis({ type: 'access/setDirty', payload: false })
        return response.data
      })
      .catch((error) => {
        dis({ type: 'access/setError', payload: 'Failed to save access settings' })
      })
  }

  function updateRules(payload) {
    dis({ type: 'access/setDirty', payload: true })
    dis({ type: 'access/updateRules', payload })
  }

  function updateRootOrgRule(grantType) {
    dis({ type: 'access/setDirty', payload: true })
    dis({ type: 'access/updateRootOrgRule', payload: grantType })
  }

  function updateSubOrgRule(externalType, enabled, accessKind) {
    if (enabled) {
      dis({ type: 'access/upsertSubOrgRule', payload: { externalType, accessKind } })
    } else {
      dis({ type: 'access/removeSubOrgRule', payload: externalType })
    }
    dis({ type: 'access/setDirty', payload: true })
  }

  function removeSubOrgRules() {
    dis({ type: 'access/setDirty', payload: true })
    dis({ type: 'access/removeSubOrgRules', payload: null })
  }

  return {
    load,
    persist,
    updateRules,
    updateRootOrgRule,
    updateSubOrgRule,
    removeSubOrgRules,
  }
}

export default access
