import { DirectUploadProvider } from 'react-activestorage-provider'
import MediaPreview from 'Shared/components/MediaPreview'
import './FileUploader.scoped.scss'

export default function FileUploader({ onUploaded, existingFilename, existingUrl, enablePreview = false, multiple = false, maxSize = null, onError = () => {} }) {

  const [filenames, setFilenames] = useState([])

  const fileInputRef = useRef(null)

  const [draggingOverTarget, setDraggingOverTarget] = useState(false)

  const handleClick = () => {
    fileInputRef.current.click()
  }

  const uploadedFilename = existingFilename || (existingUrl && existingUrl.split('/').pop())

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDraggingOverTarget(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDraggingOverTarget(false)
  }

  const validate = (files) => {
    if(!maxSize) return true;
    for(const file of files) {
      if(file.size > maxSize) {
        onError('File size too large')
        return false;
      }
    }
    return true
  }

  return <DirectUploadProvider
    onSuccess={(ids) => onUploaded(ids, multiple ? filenames : filenames[0])}
    render={({ handleUpload, uploads, ready }) => {
      const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDraggingOverTarget(false)
        if(validate(e.dataTransfer.files)) {
          setFilenames(Array.from(e.dataTransfer.files).map((f) => f.name))
          handleUpload(e.dataTransfer.files)
        }
      }

      return (
        <div className="image-uploader">
          <div className={draggingOverTarget ? 'drag-target active' : 'drag-target'}
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={handleDragEnter}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}>
            <SvgIconsUpload />
            <span>Drag and drop or click to upload</span>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            multiple={multiple}
            disabled={!ready}
            onChange={e => {
              if(validate(e.currentTarget.files)) {
                setFilenames(Array.from(e.currentTarget.files).map((f) => f.name))
                handleUpload(e.currentTarget.files)
              }
            }
            }
          />

          {uploadedFilename &&
            <p className="no-margin-top">Existing file: {uploadedFilename}</p>
          }

          {enablePreview && existingUrl &&
            <MediaPreview url={existingUrl} />
          }

          {uploads.map(upload => {
            switch (upload.state) {
              case 'waiting':
                return <p key={upload.id}>Waiting to upload {upload.file.name}</p>
              case 'uploading':
                return (
                  <p key={upload.id}>
                    Uploading {upload.file.name}: {upload.progress}%
                  </p>
                )
              case 'error':
                return (
                  <p key={upload.id}>
                    Error uploading {upload.file.name}: {upload.error}
                  </p>
                )
              case 'finished':
                return (
                  <p key={upload.id}>Finished uploading {upload.file.name}</p>
                )
            }
          })}
        </div>
      )
    }}
  />
}

