import Pusher from 'pusher-js'
import { parseISO, add, isAfter } from 'date-fns'

const KEYS = ['sentAt', 'updatedAt']

export default function createPusherManager(
  handlePayload,
  handleLatePayload,
  handleConnected
) {
  let storedChannelName
  let lastPayloadTimes = {}

  let config = {
    cluster: window.ENV.PUSHER_REGION,
    forceTLS: true,
    authEndpoint: '/api/pusher/auth',
  }
  if (window.ENV.PUSHER_HOST) {
    config = {
      ...config,
      wsHost: window.ENV.PUSHER_HOST,
      httpHost: window.ENV.PUSHER_HOST,
    }
  }

  const pusher = new Pusher(window.ENV.PUSHER_KEY, config)

  pusher.connection.bind('state_change', ({ current }) => {
    if (current === 'connected') {
      console.log('Pusher connected')
      if (handleConnected) {
        handleConnected()
      }
    } else {
      console.log('Pusher disconnected')
    }
  })

  function subscribeToChannel(channelName) {
    const channel = pusher.subscribe(channelName)
    channel.bind('changed', (snakeMessage) => {
      const message = camelizeKeys(snakeMessage)

      // handle sentAt and updatedAt by checking if this message has arrived out of order
      // if so we'll notify our parent that it arrived late, which may trigger a full reload of state
      // sentAt: the time this payload was dispatched from the server
      // updatedAt: the internal time this payload refers to in state
      KEYS.forEach((key) => {
        if (typeof message[key] !== 'undefined') {
          const date = parseISO(message[key])
          const lastPayloadTime = lastPayloadTimes[key]

          if (lastPayloadTime && isAfter(lastPayloadTime, date)) {
            handleLatePayload(storedChannelName, 'out_of_order')
            if (!window.ENV.CIRCLE_CI) {
              throw new OutOfOrderPayloadError("Payload arrived out of order", message);
            }
          }

          // only trigger a full re-pull for a late payload if it's sentAt
          if (key === 'sentAt' && isAfter(new Date(), add(date, { seconds: 5 }))) {
            handleLatePayload(storedChannelName, 'late')
            if (!window.ENV.CIRCLE_CI) {
              throw new LatePayloadError("Payload arrived late", message);
            }
          }

          lastPayloadTimes[key] = date
        }
      })

      handlePayload(message)
    })
  }

  function unsubscribeFromChannel(channelName) {
    pusher.unsubscribe(channelName)
  }

  function setChannelName(channelName) {
    storedChannelName = channelName
    const fullChannelName = `private-${window.ENV.PUSHER_CHANNEL_PREFIX}-${channelName}`

    subscribeToChannel(fullChannelName)
    return () => unsubscribeFromChannel(fullChannelName)
  }

  return {
    setChannelName,
  }
}
class LatePayloadError extends Error {
  constructor(message, payload) {
    super(`Payload ${JSON.stringify(payload)} arrived more than 5 seconds late`);
    this.name = "LatePayloadError"; // Custom name for the error
    this.payload = payload; // Store the payload for further use
  }
}

class OutOfOrderPayloadError extends Error {
  constructor(message, payload) {
    super(`Payload ${JSON.stringify(payload)} arrived out of order`);
    this.name = "OutOfOrderPayloadError"; // Custom name for the error
    this.payload = payload; // Store the payload for further use
  }
}
