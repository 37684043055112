import { createSlice } from '@reduxjs/toolkit'

const flowTemplateSlice = createSlice({
  name: 'flowTemplate',
  initialState: [],
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    set: (s, a) => a.payload,
  },
}).reducer

const local = createSlice({
  name: 'local',
  initialState: {
    saving: false,
    error: null,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
}).reducer

const reducers = {
  local,
  flowTemplate: flowTemplateSlice
}

export default reducers
