import { AxiosInstance } from 'axios'
import { TARGET_ACTIVITY_DEFAULT_COUNT } from '../constants'
import type { DispatchType, StoreType } from '../store'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (dis: DispatchType, store: StoreType, client: AxiosInstance) => {
  function load(id) {
    return client.get(`/api/targets/${id}/show_long`).then((res) => {
      dis({
        type: 'target/set',
        payload: res.data,
      })
    }).then(() => {
      // Some data only available from this endpoint
      client.get(`/api/targets/${id}`).then((res) => {
        dis({
          type: 'target/upsert',
          payload: res.data,
        })
      })
    })
  }

  function loadBasic(id) {
    return client.get(`/api/targets/${id}/basic_info`).then(({ data }) => {
      dis({
        type: 'target/set',
        payload: data,
      })
    })
  }

  function loadNotes() {
    const {
      target: { id },
    } = store.getState()
    return client.get(`/api/targets/${id}/notes`).then(({ data: { notes } }) => {
      dis({ type: 'target/upsert', payload: { notes } })
    })
  }

  function loadActivity(countToLoad = TARGET_ACTIVITY_DEFAULT_COUNT) {
    const {
      target: { id },
    } = store.getState()
    return client
      .get(`/api/targets/${id}/activity?limit=${countToLoad}`)
      .then(({ data: { activity } }) => {
        dis({ type: 'target/upsert', payload: { activity } })
      })
  }

  function addCustomField(id, value) {
    const target = store.getState().target
    return client
      .get(`/api/custom_fields/${id}`)
      .then(({ data: { slug } }) => {
        return client.post(`/api/targets/${target.id}/custom_fields`, {
          customFields: { [slug]: value },
        })
      })
      .then(() => load(target.id))
  }

  function updateCustomField(slug, value) {
    const target = store.getState().target
    return client
      .post(`/api/targets/${target.id}/custom_fields`, {
        customFields: { [slug]: value },
      })
      .then(() => load(target.id))
  }

  function sendSms(body) {
    const target = store.getState().target
    return client.post(`/api/targets/${target.id}/send_sms`, { body })
  }

  function generateVerifyUrl() {
    const target = store.getState().target
    return client
      .post(`/api/targets/${target.id}/generate_verify_url`)
      .then((response) => response.data.url)
  }

  function generateCard() {
    const target = store.getState().target
    return client
      .post(`/api/targets/${target.id}/generate_card`)
      .then(() => load(target.id))
  }

  return {
    load,
    loadBasic,
    loadNotes,
    loadActivity,
    addCustomField,
    updateCustomField,
    sendSms,
    generateVerifyUrl,
    generateCard
  }
}
