import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import restClient from 'Shared/hooks/restClient'
import Wizard from './Wizard'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'
import './PageContainer.scoped.scss'

export default function PageContainer() {
  const [wizard, setWizard] = useState(null)
  const [wizards, setWizards] = useState([])

  useEffect(() => {
    restClient.get('/api/flows/available_wizards')
      .then(response => setWizards(response.data))
  }, [])

  return (
    <div>
      {!wizard && <ChooseWizard wizards={wizards} setWizard={setWizard} />}
      {wizard && <WizardForm wizard={wizard} key={wizard.slug} setWizard={setWizard} />}
    </div>
  )
}

function ChooseWizard({ wizards, setWizard }) {
  return <Wizard wizards={wizards} setWizard={setWizard} />
}

function WizardForm({ wizard, setWizard }) {
  const initialValues = wizard.fields.reduce((acc, field) => {
    if (field.questionType === 'checkbox' && field.options) {
      const defaultOption = field.options.find(option => option.default)
      acc[field.slug] = defaultOption ? defaultOption.value : ''
    } else if (field.questionType === 'select' && field.options) {
      acc[field.slug] = field.options[0].value
    } else {
      acc[field.slug] = ''
    }
    return acc
  }, {})

  const validationSchema = Yup.object().shape(
    wizard.fields.reduce((acc, field) => {
      let validationRule = Yup.string()

      if (field.questionType !== 'checkbox' && field.required !== false) {
        validationRule = validationRule.required('This field is required')
      }

      if (field.questionType === 'url') {
        validationRule = validationRule.matches(
          /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/i,
          'Please enter a valid URL'
        )
      }

      acc[field.slug] = validationRule
      return acc
    }, {})
  )

  const handleSubmit = (values, { setSubmitting }) => {
    restClient.post('/api/flows', {
      wizardSlug: wizard.slug,
      wizardFields: values,
      name: 'New Flow'
    })
      .then(({ data }) => {
        const flowId = data.id
        window.location.href = `/admin/flows/${flowId}/build?nav=preview`
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const disabled = ({ values }) => !_.every(wizard.fields, field => {
    // If field is not required, always return true
    if (!field.required) return true;

    // For checkbox fields, an empty string is a valid state
    if (field.questionType === 'checkbox') {
      // values[field.slug] will be either the selected value or an empty string
      return true;
    }

    // For all other field types, check for truthy values
    return !!values[field.slug];
  })

  return (
    <div>
      <div className="header margin-left">
        <button className="button naked margin-bottom" onClick={() => setWizard(null)}><SvgIconsBack /></button>
        <h1 className="margin-bottom">{wizard.name}</h1>
        <button className="button secondary small margin-bottom" onClick={() => setWizard(null)}>Finish later</button>
      </div>
      <div className="row">
        <div className="box form">
          <h1>Page details</h1>
          <p className="margin-bottom double">Add some details to get started</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form>
                {wizard.fields.map(field => (
                  <div className="field shorten" key={field.slug}>
                    <label>{field.label}</label>
                    <div className="url-box">
                      {field.preText && <p>{field.preText}</p>}
                      {(field.questionType === 'text' || field.questionType === 'url') && (
                        <Field
                          type="text"
                          name={field.slug}
                          placeholder={field.placeholder}
                        />
                      )}
                    </div>
                    {field.questionType === 'paragraph' && (
                      <Field
                        as="textarea"
                        name={field.slug}
                        placeholder={field.placeholder}
                      />
                    )}
                    {field.questionType === 'select' && (
                      <Field as="select" name={field.slug}>
                        <option value="" disabled>{field.placeholder || "Please select an option"}</option>
                        {field.options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    )}
                    {field.questionType === 'checkbox' && (
                      field.options.map((option, index) => (
                        <div key={index} className="checkbox">
                          <Field name={field.slug}>
                            {({ field: formField, form }) => (
                              <input
                                type="checkbox"
                                id={option.value}
                                checked={formField.value === option.value}
                                onChange={e => {
                                  form.setFieldValue(
                                    field.slug,
                                    e.target.checked ? option.value : ''
                                  )
                                }}
                              />
                            )}
                          </Field>
                          <label htmlFor={option.value}>{option.label}</label>
                        </div>
                      ))
                    )}
                    {field.questionType === 'image' &&
                      <Field name={field.slug}>
                        {({ field: formField, form }) => (
                          <div className="flex-center gap-10">
                            <FileUploader onUploaded={(blobIds) => form.setFieldValue(field.slug, blobIds[0])} />
                            {!!formField.value && <img src={`/rails/active_storage/blobs/${formField.value}/image.png`} width={150} />}
                          </div>
                        )}
                      </Field>
                    }
                    {field.description && (
                      <p className="text-small">{field.description}</p>
                    )}
                    <ErrorMessage
                      name={field.slug}
                      component={({ children }) => (
                        <p className="smaller error no-margin-bottom no-margin-top">{children}</p>
                      )}
                    />
                  </div>
                ))}
                <div className="field">
                  <button
                    type="submit"
                    className="button primary"
                    disabled={isSubmitting || disabled({ values })}
                  >
                    Create Page
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
