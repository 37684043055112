import { useAct } from 'FlowBuilder/store'
import SharedModal from 'Shared/components/Modal/Modal'
import AddQuestion from './AddQuestion'
import EditContent from './EditContent'
import AddAction from './AddAction'

export default function Modal() {
  const act = useAct()
  const { kind } = useSel(s => s.local.modal)

  function title(kind) {
    switch (kind) {
      case 'addQuestion':
        return 'Add a question';
      case 'editContent':
        return 'Edit content';
      case 'addAction':
        return 'Add step to flow';
      default:
        return ''; // or some default value
    }
  }

  return <SharedModal open={true} title={title(kind)} onCancel={act.local.closeModal}>
    {kind === 'addQuestion' && <AddQuestion />}
    {kind === 'editContent' && <EditContent />}
    {kind === 'addAction' && <AddAction />}
  </SharedModal>
}
