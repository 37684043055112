import { Provider } from 'react-redux'
import store from '../store'
import Builder from './Builder'

export default function FlowTemplateBuilder({ flowTemplateId }) {
  return (
    <Provider store={store}>
      <Builder flowTemplateId={flowTemplateId} /> 
    </Provider>
  )
}
