export default function RedirectActionView() {

  const action = useSel(s => s.actions.entities[s.local.selectedActionId])
  const config = action.config || { redirectUrl: '' }

  return <div className="box narrow">
    <h2 className='no-margin-top'>Redirect</h2>
    <div className='field'>
      <label className="bold">This step will redirect to:</label>
      <p className="no-margin-bottom">{config.redirectUrl}</p>
    </div>
  </div>
}
