import { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import pluralize from 'pluralize'
import './AsyncSelect.scoped.scss'

const AsyncSelectComponent = ({
  redirectUrl,
  value,
  valueObject,
  onChange,
  entity,
  queryParameters = {},
  multiple = false,
  disabled = false,
  defaultInputValue = 'Start typing',
  loadStaticList = false,
  baseEndpoint,
  rootClassName = 'root',
  ...rest
}) => {
  const [defaultOptions, setDefaultOptions] = useState(null)
  const client = applyCaseMiddleware(axios.create())
  const labels = useRef({})
  const data = useRef({})

  const getEndpoint = () => {
    if (baseEndpoint) return baseEndpoint
    return `/api/${_.snakeCase(pluralize(entity))}`
  }

  function applyDefaultOptions(options) {
    options.forEach(({ value, label }) => {
      labels.current[value] = label
    })
    setDefaultOptions(options)
  }

  useEffect(() => {
    if (value) {
      if (multiple && value.length > 0) {
        client
          .get(getEndpoint(), { params: { ids: value } })
          .then(({ data }) => applyDefaultOptions(data))
      } else if (!multiple) {
        client
          .get(`${getEndpoint()}/${value}`)
          .then(({ data: { id, name } }) =>
            applyDefaultOptions([{ value: id, label: name }])
          )
      }
    } else {
      applyDefaultOptions([])
    }
  }, [])

  useEffect(() => {
    if (disabled) return

    if (loadStaticList) {
      client
        .get(getEndpoint(), { params: queryParameters })
        .then(({ data }) => applyDefaultOptions(data))
    }
  }, [JSON.stringify(queryParameters)])

  const dLoadOptions = useMemo(() => {
    function loadOptions(text, callback) {
      client
        .get(`${getEndpoint()}/search`, {
          params: _.merge(queryParameters, { query: text }),
        })
        .then(({ data }) => callback(data))
    }

    return _.debounce(loadOptions, 500)
  }, [queryParameters])

  if (!defaultOptions) {
    return <div>Loading...</div>
  }

  function handleChange(objs) {
    if (multiple) {
      const values = objs ? objs.map((v) => v.value) : []
        ; (objs || []).forEach(({ value, label }) => {
          labels.current[value] = label
        })
      onChange(values, objs)
    } else {
      if (objs) {
        labels.current[objs.value] = objs.label
        data.current[objs.value] = objs
        onChange(objs.value, objs)
      } else {
        onChange(null, null)
      }
    }
  }

  function selectValue() {
    if (multiple) {
      if (!value) return []
      return value.map((v) => ({ value: v, label: labels.current[v] }))
    } else {
      if (!value) return null
      return { value, label: labels.current[value], ...data.current[value] }
    }
  }

  return (
    <div className={rootClassName}>
      <AsyncSelect
        cacheOptions
        isMulti={multiple}
        loadOptions={dLoadOptions}
        defaultOptions={defaultOptions}
        defaultValue={defaultOptions}
        value={valueObject || selectValue()}
        isDisabled={disabled}
        redirectUrl={redirectUrl}
        noOptionsMessage={() => 'Start typing'}
        onChange={handleChange}
        filterOption={createFilter({ ignoreAccents: false })}
        classNamePrefix="react-select"
        {...rest}
      />
    </div>
  )
}

export default AsyncSelectComponent
