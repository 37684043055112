import Modal from 'Shared/components/Modal/Modal'
import AccessControl from './AccessControl'

export default function AccessModal({ entityType, entityId, entityName, open, hide }) {
  const [resetTrigger, setResetTrigger] = useState(0)

  useEffect(() => {
    if (open) {
      setResetTrigger((prev) => prev + 1)
    }
  }, [open])

  return (
    <Modal title={`Share this ${entityName}`} open={open} onCancel={hide}>
      <AccessControl entityType={entityType} entityId={entityId} entityName={entityName} />
    </Modal>
  )
}
