import Button from 'Shared/components/Button'
import AccessModal from './AccessModal'

export default function ShareButton({
  entityType,
  entityId,
  entityName,
  label = 'Share',
  onBlur,
}) {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (onBlur) {
      onBlur()
    }
  }, [modalOpen])

  return (
    <>
      <Button secondary onClick={() => setModalOpen(true)}>
        {label}
        <SvgIconsShareNetwork />
      </Button>

      <AccessModal
        entityType={entityType}
        entityId={entityId}
        entityName={entityName}
        open={modalOpen}
        hide={() => setModalOpen(false)}
      />
    </>
  )
}
