const types = {
  instruction: {
    name: 'Instruction',
    icon: SvgIconsNote,
    multiline: true,
    showRequired: false
  },
  text: {
    name: 'Text',
    icon: SvgIconsQuestion,
    showRequired: true,
    customFieldKind: 'string',
  },
  multiple_choice: {
    name: 'Multiple choice',
    icon: SvgIconsQuestion,
    showRequired: true,
    hasAnswers: true,
    customFieldKind: 'string',
  },
  multiple_select: {
    name: 'Multiple select',
    icon: SvgIconsQuestion,
    showRequired: true,
    hasAnswers: true,
  },
  name: {
    name: 'Name',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  email: {
    name: 'Email',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  postcode: {
    name: 'Postcode',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  phone_number: {
    name: 'Phone number',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  opt_in_radio: {
    name: 'Opt-in radio',
    icon: SvgIconsQuestion,
    defaultRequired: true,
    settings: [
      {
        key: 'topics', label: 'Opt-in topics', type: 'multi_select', options: [
          { label: 'Email', value: 'email_default' },
          { label: 'SMS', value: 'sms_default' },
          { label: 'Phone', value: 'phone_default' },
          { label: 'Whatsapp', value: 'whatsapp_default' },
        ]
      },
      { key: 'yesLabel', label: 'Yes label', type: 'text' },
      { key: 'noLabel', label: 'No label', type: 'text' },
      { key: 'optOutPrompt', label: 'Prompt if someone opts out', type: 'checkbox_and_message' },
      { key: 'optOutMessage', label: 'Message', type: 'text_opt_out' },
    ]
  },
  score: {
    name: 'Score',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  file: {
    name: 'File upload',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  date: {
    name: 'Date',
    icon: SvgIconsQuestion,
    showRequired: true,
  },
  // ranking: {
  //   name: 'Ranking',
  //   icon: SvgIconsQuestion,
  //   showRequired: true,
  // },
}

export default types;

