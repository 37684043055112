import FileUploader from 'Shared/components/ImageUploader/FileUploader'
import { useField, Field } from 'formik';

export default function File({ response, setResponse, name, disabled = false, formikName }) {
  const [filenames, setFilenames] = useState([])
  const [field, meta, helpers] = useField({name: formikName});

  const handleUpload = (blobIds, filenames) => {
    helpers.setTouched()
    setFilenames(filenames)
    setResponse(blobIds)
  }

  const onError = (msg) => {
    helpers.setTouched().then(() => helpers.setError(msg))
  }

  return (
    <div className="answers">
      <FileUploader
        onUploaded={handleUpload}
        onError={onError}
        maxSize={20000000}
        multiple
      />
      {!!filenames.length &&
        <p>
          Uploaded: {filenames.join(', ')}
        </p>
      }
    </div>
  )
}
