import './PillEditor.scoped.scss'
import { Popover } from 'react-tiny-popover'
import MergeTagList from './MergeTagList.jsx'
import {
  Editor,
  EditorState,
  Modifier,
  CompositeDecorator,
  RichUtils,
  DefaultDraftBlockRenderMap,
  convertFromRaw,
  getDefaultKeyBinding,
} from 'draft-js'
import 'draft-js/dist/Draft.css'

// converters
import convertFromHtml from './converters/fromHtml'
import generateRawText from './converters/toText'
import generateHtml from './converters/toHtml'
import convertTextToDraftJsFormat from './converters/fromText'

// entities
import Link from './entities/Link'
import Placeholder from './entities/Placeholder'

// editors
import EditLink from './EditLink'
import emojiData from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'

const makeEntityFinder = (type) => (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === type
  }, callback)
}

const PillEditor = ({
  webLink = false,
  condensed = false,
  initialValue,
  onChange,
  availableTags,
  enableEmojis = false,
  richText = false,
  singleLine = false,
  allowNewLines = false,
  setIsDropdownOpen,
  placeholder = '',
  ...props
}) => {
  let value = initialValue || ''

  return (
    <div {...props}>
      <EntityEditor
        initialValue={value}
        onChange={(body) => {
          return onChange(body)
        }}
        webLink={webLink}
        entities={availableTags}
        condensed={condensed}
        richText={richText}
        singleLine={singleLine}
        allowNewLines={allowNewLines}
        enableEmojis={enableEmojis}
        setIsInsideDropdownOpen={setIsDropdownOpen}
        placeholder={placeholder}
      />
    </div>
  )
}

const STYLES = ['BOLD', 'ITALIC', 'UNDERLINE']
const BLOCK_TYPES = [{ type: 'unordered-list-item', icon: 'LIST' }, { type: 'ordered-list-item', icon: 'ORDERED_LIST' }]
const ICONS = {
  BOLD: <SvgIconsBold />,
  ITALIC: <SvgIconsItalic />,
  UNDERLINE: <SvgIconsUnderline />,
  LIST: <SvgIconsList />,
  ORDERED_LIST: <SvgIconsOrderedList />,
  LINK: <SvgIconsLink />,
  EMOJI: <SvgIconsEmoji />,
}

const EntityEditor = ({
  webLink,
  condensed,
  initialValue,
  onChange,
  entities,
  richText = false,
  singleLine = false,
  allowNewLines = false,
  enableEmojis = false,
  setIsInsideDropdownOpen,
  placeholder = '',
}) => {
  const [editorState, setEditorState] = React.useState(null)
  const [isFirstPopoverOpen, setIsFirstPopoverOpen] = useState(false)
  const [isSecondPopoverOpen, setIsSecondPopoverOpen] = useState(false)
  const [isEmojiPopoverOpen, setIsEmojiPopoverOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const editorRef = React.useRef(null)

  // set the initial editor state
  // this transmutes the stored content from either raw/html into pill form
  useEffect(() => {
    const text = initialValue || ''
    let raw = null
    if (richText) {
      raw = convertFromHtml(text)
    } else {
      raw = convertFromRaw(convertTextToDraftJsFormat(text))
    }

    const decorator = new CompositeDecorator([
      {
        strategy: makeEntityFinder('PLACEHOLDER'),
        component: Placeholder,
      },
      {
        strategy: makeEntityFinder('LINK'),
        component: Link,
      },
    ])

    setEditorState(EditorState.createWithContent(raw, decorator))
  }, [])

  const handleChange = (editorState) => {
    setEditorState(editorState)
    if (onChange) {
      const contentState = editorState.getCurrentContent()
      const output = richText ? generateHtml(contentState) : generateRawText(contentState)
      onChange(output)
    }
  }

  const handleLinkEdit = (editorState) => {
    setEditorState(editorState)
    handleChange(editorState)
  }

  function isActive(style) {
    const currentStyle = editorState.getCurrentInlineStyle()
    return currentStyle.has(style)
  }

  function isBlockActive(type) {
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()
    return blockType === type
  }

  function isLinkActive() {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const block = contentState.getBlockForKey(startKey)
    const linkKey = block.getEntityAt(startOffset)

    if (linkKey === null) {
      return false
    }

    const entity = contentState.getEntity(linkKey)
    return entity.getType() === 'LINK'
  }

  function toggle(style) {
    const updatedState = RichUtils.toggleInlineStyle(editorState, style)
    setEditorState(updatedState)
    handleChange(updatedState)
  }

  function toggleBlock(type) {
    const updatedState = RichUtils.toggleBlockType(editorState, type)
    setEditorState(updatedState)
    handleChange(updatedState)
  }

  function onTab(e) {
    const maxDepth = 4;
    const updatedState = RichUtils.onTab(e, editorState, maxDepth)
    setEditorState(updatedState)
    handleChange(updatedState)
  }

  function handleKeyCommand(command) {
    if (!richText) {
      return null
    }
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const insertPlaceholder = (label) => {
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    currentContent.createEntity('PLACEHOLDER', 'IMMUTABLE', { label })
    const entityKey = currentContent.getLastCreatedEntityKey()

    const textWithEntity = Modifier.insertText(
      currentContent,
      selection,
      label,
      null,
      entityKey
    )

    setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'))
    // find a better way
    setTimeout(focus, 100)
  }

  const insertEmoji = (emoji) => {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()
    const collapsedSelection = selectionState.isCollapsed()
      ? selectionState
      : selectionState.merge({
        anchorOffset: selectionState.getEndOffset(),
        focusOffset: selectionState.getEndOffset(),
      })
    const newContentState = Modifier.insertText(
      contentState,
      collapsedSelection,
      emoji.native
    )
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters'
    )
    setEditorState(newEditorState)
    focus()
  }

  const focus = () => {
    editorRef.current.focus()
  }

  function blockStyleFn(contentBlock) {
    if (contentBlock.getType() === 'unstyled' || contentBlock.getType() === 'unordered-list-item' ||
      contentBlock.getType() === 'ordered-list-item') {
      return 'injected-styles'
    }
  }

  const keyboardShortcuts = (event) => {
    if (event.key === 'b' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      toggle('BOLD')
    }
    if (event.key === 'i' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      toggle('ITALIC')
    }
    if (event.key === 'u' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      toggle('UNDERLINE')
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyboardShortcuts)
    return () => {
      document.removeEventListener('keydown', keyboardShortcuts)
    }
  }, [editorState])

  const keyBindingFn = (e) => {
    if (singleLine && !allowNewLines && e.key === 'Enter') {
      e.preventDefault()
      return null
    }
    return getDefaultKeyBinding(e)
  }

  return editorState ? (
    <div className={singleLine ? 'pill-editor single-line' : 'pill-editor'}>
      {richText && (
        <div className="toolbar">
          {STYLES.map((style, i) => {
            return (
              <button
                key={`style-${i}`}
                className={`button smallest ${isActive(style) ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault()
                  toggle(style)
                }}
              >
                {ICONS[style]}
              </button>
            )
          })}
          {BLOCK_TYPES.map(({ type, icon }, i) => {
            return (
              <button
                key={`block-${i}`}
                className={`button smallest ${isBlockActive(type) ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault()
                  toggleBlock(type)
                }}
              >
                {ICONS[icon]}
              </button>
            )
          })}
          <Popover
            containerStyle={{ zIndex: 1010 }}
            containerClassName="popover-container"
            isOpen={isFirstPopoverOpen}
            positions={['bottom', 'top']}
            padding={0}
            reposition={true}
            align={'start'}
            content={() => (
              <EditLink
                mergeTags={entities}
                visible={true}
                close={() => setIsFirstPopoverOpen(false)}
                editorState={editorState}
                onSave={handleLinkEdit}
                setIsDropdownOpen={setIsDropdownOpen}
              />
            )}
            onClickOutside={() => setIsFirstPopoverOpen(isDropdownOpen)}
          >
            <button
              onClick={(e) => {
                e.preventDefault()
                setIsFirstPopoverOpen(!isFirstPopoverOpen)
              }}
              className={`button smallest ${isLinkActive() ? 'active' : ''}`}
            >
              {ICONS.LINK}
            </button>
          </Popover>
          <Popover
            containerStyle={{ zIndex: 1010 }}
            containerClassName="popover-container"
            isOpen={isSecondPopoverOpen}
            positions={['bottom', 'top']}
            padding={0}
            reposition={true}
            align={'start'}
            content={() => (
              <MergeTagList
                mergeTags={entities}
                insert={insertPlaceholder}
                close={() => setIsSecondPopoverOpen(false)}
                mode='slug'
              />
            )}
            onClickOutside={() => setIsSecondPopoverOpen(false)}
          >
            <button
              onClick={(e) => {
                e.preventDefault()
                setIsSecondPopoverOpen(!isSecondPopoverOpen)
              }}
              className="button smallest"
            >
              Merge tags
            </button>
          </Popover>
        </div>
      )}

      <div
        className={`text-box ${richText ? 'border margin-left margin-right' : !webLink ? 'flex-center' : ''}`}
      >
        <div className={`grow ${webLink ? 'break-word' : ''}`}>
          <Editor
            editorState={editorState}
            ref={editorRef}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
            onTab={onTab}
            blockStyleFn={blockStyleFn}
            placeholder={placeholder}
          />
        </div>
        <div
          className="merge-tags-dropdown align-center gap-5"
          style={
            webLink ? { transform: 'translate(-5px, 5px)', marginTop: '5px' } : undefined
          }
        >
          {!richText && (
            <>
              {enableEmojis && (
                <Popover
                  containerStyle={{ zIndex: 1010 }}
                  containerClassName="popover-container"
                  isOpen={isEmojiPopoverOpen}
                  positions={['bottom', 'top']}
                  padding={0}
                  reposition={true}
                  align={'start'}
                  content={() => (
                    <EmojiPicker data={emojiData} onEmojiSelect={insertEmoji} />
                  )}
                  onClickOutside={() => setIsEmojiPopoverOpen(false)}
                >
                  <button
                    className="add-emoji-button"
                    type="button"
                    onClick={() => setIsEmojiPopoverOpen(!isEmojiPopoverOpen)}
                  >
                    <SvgIconsAddEmoji />
                  </button>
                </Popover>
              )}

              <Popover
                containerStyle={{ zIndex: 1010 }}
                containerClassName="popover-container"
                isOpen={isDropdownOpen}
                positions={['bottom', 'top', 'left']}
                padding={0}
                reposition={true}
                align={'start'}
                content={() => (
                  <MergeTagList
                    mergeTags={entities}
                    insert={insertPlaceholder}
                    mode='slug'
                    close={() => {
                      setIsDropdownOpen(false)
                      setIsInsideDropdownOpen?.(false) || (() => { })
                    }}
                  />
                )}
                onClickOutside={() => {
                  setIsDropdownOpen(false)
                  setIsInsideDropdownOpen?.(false) || (() => { })
                }}
              >
                <button
                  className="merge-tags-dropdown-button"
                  type="button"
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen)
                    setIsInsideDropdownOpen?.(true) || (() => { })
                  }}
                >
                  <SvgIconsPlus />
                </button>
              </Popover>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null
}

const MemoizedPillEditor = React.memo(PillEditor)
export default MemoizedPillEditor
