import { useCallback } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import TextSingleLine from 'Survey/components/question-types/TextSingleLine'
import MultipleChoice from 'Survey/components/question-types/MultipleChoice'
import MultipleSelect from 'Survey/components/question-types/MultipleSelect'
import Date from 'Survey/components/question-types/Date'
import File  from 'Survey/components/question-types/File'
import Score from 'Survey/components/question-types/Score'
import PhoneNumber from 'Survey/components/question-types/PhoneNumber'
import OptInRadio from 'Survey/components/question-types/OptInRadio'
import SafeRenderHtml from 'CampaignVolunteer/components/SafeRenderHtml'
import phone from 'phone'

const questionTypeComponents = {
  'text': TextSingleLine,
  'multiple_choice': MultipleChoice, // radios
  'multiple_select': MultipleSelect, // checkboxes
  'score': Score,
  'ranking': Score,
  'name': TextSingleLine,
  'email': TextSingleLine,
  'postcode': TextSingleLine,
  'phone_number': PhoneNumber,
  'instruction': () => <div />,
  'opt_in_radio': OptInRadio,
  'date': Date,
  'file': File,
  'unknown': () => <div>Unknown</div>,
}

const personalQuestionTypes = ['name', 'email', 'postcode', 'phone_number']

function getValidationSchema(questions) {
  return Yup.object().shape(
    questions.reduce((acc, question) => {
      const { id, questionType, settings, hide } = question
      if (hide || questionType === 'detailsMessage') return acc

      let validationRule = Yup.string()

      if(['multiple_select', 'file'].includes(questionType)) {
        validationRule = Yup.array().of(Yup.string())
      }

      if (settings?.required) {
        switch (questionType) {
          case 'opt_in_radio':
          case 'multiple_choice':
          case 'multiple_select':
          case 'score':
            validationRule = validationRule.required('Please select an option')
            break
          case 'date':
            validationRule = validationRule.required('Please choose a date')
            break
          case 'file':
            validationRule = validationRule.required('Please upload a file')
            break
          default:
            validationRule = validationRule.required('Please enter a response')
            break
        }

        if (questionType === 'multiple_select') {
          validationRule = validationRule.min(1, 'Please select at least one option')
        }
      }

      switch (questionType) {
        case 'email':
          validationRule = validationRule.email('Please check your email address is correct')
          break

        case 'postcode':
          validationRule = validationRule.matches(
            /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/,
            'Please check your postcode is correct'
          )
          break

        case 'phone_number':
          validationRule = validationRule.test(
            'is-valid-phone',
            'Please check your phone number is correct',
            (value) =>
              !value || phone(value).isValid || phone(value, { country: 'GB' }).isValid
          )
          break

        default:
          break
      }

      acc[id] = validationRule
      return acc
    }, {})
  )
}

function transformQuestions(questions, targetDetails) {
  if (!targetDetails || !targetDetails.signedIn) {
    return questions
  }
  const detailsPresent = targetDetails.detailsPresent || {}

  let anyHidden = false
  const transformedQuestions = questions.map((q) => {
    if (personalQuestionTypes.includes(q.questionType) && detailsPresent[_.camelCase(q.questionType)]) {
      anyHidden = true
      return {
        ...q,
        hide: true
      }
    } else {
      return q
    }
  })
  // if any have been hidden, insert a message at the index of the last hidden question

  if (anyHidden) {
    const lastHiddenIndex = transformedQuestions.map(q => q.hide).lastIndexOf(true)
    transformedQuestions.splice(lastHiddenIndex + 1, 0, { questionType: 'detailsMessage', signedInAs: targetDetails.signedInAs })
  }

  return transformedQuestions
}

export default function TakeSurveyForm({ surveyId, pageNumber, onSubmit, onUpdateNoTarget = () => {} }) {
  const questions = useSel(s => {
    const allQuestions = _.values(s.questions.entities).filter(q => q.surveyId === surveyId && q.active)
    const pageQuestions = allQuestions.filter(q => q.pageNumber === pageNumber)
    return _.orderBy(pageQuestions, 'digitOrder')
  })

  const [hasSelectedNo, setHasSelectedNo] = useState(false)
  const optOutQuestion = useMemo(() => 
    questions.find(q => q.questionType === 'opt_in_radio' && q?.settings?.optOutPrompt),
    [questions]
  )
  const handleOptionChange = useCallback((values) => {
    if (optOutQuestion && values[optOutQuestion.id] === 'n') {
      setHasSelectedNo(true)
    } else {
      setHasSelectedNo(false)
    }
  }, [optOutQuestion])

  const targetDetails = useSel(s => s.taker?.targetDetails || {})
  const transformedQuestions = transformQuestions(questions, targetDetails)

  const pageCount = useSel(s => {
    const allQuestions = _.values(s.questions.entities).filter(q => q.surveyId === surveyId && q.active)
    return _.max(allQuestions.map(q => q.pageNumber))
  })

  const validationSchema = getValidationSchema(transformedQuestions)

  const initialValues = questions.reduce((acc, q) => {
    acc[q.id] = ''
    return acc
  }, {})

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, values }) => {
        useEffect(() => {
          handleOptionChange(values)
        }, [values, handleOptionChange])
        return (
          <Form className="form">
            {transformedQuestions.map((q) => {
              if (q.questionType === 'detailsMessage') {
                return <DetailsMessage key="detailsMessage" onUpdateNoTarget={onUpdateNoTarget} signedInAs={q.signedInAs} />
              } else if (q.hide) {
                return null
              } else {
                return <Question key={q.id} question={q} />
              }
            })}
            {hasSelectedNo && (
              <div className="field">
                <label>Are you sure?</label>
                <p>{optOutQuestion?.settings.optOutMessage}</p>
              </div>
            )}
            <button
              type="submit"
              className="submit-button button primary"
              disabled={isSubmitting}
            >
              {pageNumber === pageCount ? 'Submit' : 'Next'}
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

function DetailsMessage({ onUpdateNoTarget, signedInAs }) {
  function onClick() {
    onUpdateNoTarget()
  }

  return (
    <div className="field target-details-message">
      <label>You're signing as {signedInAs}</label>
      <div className='not-me-link' onClick={onClick}>Not me</div>
    </div>
  )
}

function Question({ question }) {
  const QuestionType = questionTypeComponents[question.questionType] || questionTypeComponents.unknown

  if (!QuestionType) {
    throw new Error(`No question type component found for ${question.questionType}`)
  }

  const answers = useSel(s => _.values(s.answers.entities).filter(a => a.questionId === question.id))
  const label = String(question.question).replaceAll("\n", "<br />")
  const fieldName = question.id

  return (
    <div className="field">
      {question.questionType !== 'instruction' && <label>{label}</label>}
      {question.questionType === 'instruction' && <SafeRenderHtml html={label} />}
      <Field name={fieldName}>
        {({ field, form }) => {
          const setResponse = (value) => form.setFieldValue(field.name, value)
          const response = field.value

          return (
            <QuestionType
              name={field.name}
              answers={answers}
              response={response}
              setResponse={setResponse}
              settings={question.settings}
              placeholder={question.question}
              disabled={form.isSubmitting}
              formikName={fieldName}
            />
          );
        }}
      </Field>
      <ErrorMessage
        name={fieldName}
        component={({ children }) => {
          return <p className="smaller error no-margin-bottom">{children}</p>
        }}
      />
    </div>
  )
}
