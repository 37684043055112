import Loading from 'Shared/components/Loading/Loading'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'

import SmsPreview from 'CampaignBuilder/components/Steps/SmsPreview'
import './TextOverview.scoped.scss'
import './OverviewStructure.scoped.scss'
import { useEffect, useState } from 'react'
import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'
import OverviewStats from 'CampaignStats/components/OverviewStats'
import TextMessageContentTabs from 'CampaignBuilder/components/Steps/TextMessageContentTabs'
import SimplePaginator from 'Shared/components/SimplePaginator'
import Boxes from 'Shared/components/Boxes'
import StatsOverview from './StatsOverview'
import { formatPercent } from './utils/dateAndMathsUtils'
import { buildQuestionString } from 'Shared/components/QuestionPhonePreview'

function prettify(body, mergeTags) {
  let prettyBody = '<span>' + body.replaceAll('\n', '</span><span>') + '</span>'
  for (const [slug, tag] of Object.entries(mergeTags)) {
    prettyBody = prettyBody.replaceAll(
      `{{${slug}}}`,
      `<span class="merge-tag blue">${tag}</span>`
    )
  }
  return prettyBody
}

function Preview({ message, renderedBody, textMessageBlast }) {
  return (
    <div>
      <h2>{message} Preview</h2>
      <TextMessageContentTabs />
      <SmsPreview
        name={'Test'}
        content={{ body: renderedBody }}
        kind={textMessageBlast?.kind}
        actions={[]}
      />
    </div>
  )
}

function Links({ links, finishedSending }) {
  const hasLinks = links.length > 0
  const noLinksReason = finishedSending
    ? 'No tracked links in this message.'
    : 'Links stats will be shown once your message is sent.'

  return (
    <div>
      <h2>Links</h2>
      {!hasLinks && <p>{noLinksReason}</p>}
      {hasLinks && (
        <ul>
          {links.map(({ url, count }) => (
            <li key={url}>
              <strong>{count}</strong> - {url}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default function TextOverview() {
  const act = useAct()
  const scriptActions = useScriptActions()
  const [mergeTags, setMergeTags] = useState(null)
  const [whatsappContent, setWhatsappContent] = useState('')
  const textMessageBlast = useSel((s) => s.campaign?.textMessageBlast)
  const contentVariants = useSel(
    (s) => s.campaign?.textMessageBlast?.contentVariants || []
  )
  const currentContentVariantId = useSel((s) => s.local.currentContentVariantId)
  const whatsappTemplateId = useSel(
    (s) => s.campaign?.textMessageBlast?.whatsappTemplateId
  )
  const isWorkflowControlled = useSel((s) => s.local.isWorkflowControlled)
  const [overviewPage, setOverviewPage] = useState(1)
  const size = useWindowSize()

  const questions = useSel((s) => _.values(s.questions.entities) || [])
  const answers = useSel(s => {
    if (!questions.length) return []
    return _.orderBy(
      _.values(s.answers.entities).filter(a => a.questionId === questions[0].id && a.active),
      'digitOrder'
    )
  })

  let campaignStats = useSel((s) => s.campaign?.stats || [{}])

  // TODO: REMOVE when migrated
  // backwards compatibility with old stats saved in DB
  if (!_.isArray(campaignStats)) {
    campaignStats = [campaignStats]
  }

  // aggregate all numeric stats from variants
  const {
    textMessagesCount,
    failedCount,
    deliveredCount,
    repliedCount,
    completedCount,
    clickedCount,
    unsubscribeCount,
    targetsCount,
    goalCompletionsCount,
  } = _.reduce(
    campaignStats,
    (acc, stats) => _.mapValues(acc, (value, key) => value + stats[key]),
    {
      textMessagesCount: 0,
      failedCount: 0,
      deliveredCount: 0,
      readCount: 0,
      repliedCount: 0,
      surveysCount: 0,
      completedCount: 0,
      clickedCount: 0,
      outstandingConversationsCount: 0,
      unsubscribeCount: 0,
      targetsCount: 0,
      goalCompletionsCount: 0,
      sentWider: false,
    }
  )

  // aggregate all link stats
  const links = _.chain(campaignStats)
    .flatMap('links')
    .groupBy('url')
    .map((group, url) => ({
      url,
      count: _.sumBy(group, 'count'),
    }))
    .value()

  // campaign metadata
  const { campaignType, surveyId } = useSel((s) => s.campaign || {})

  let body
  if (campaignType === 'sms_survey') {
    body = questions.length > 0 ? questions[0].question : ''
    if (body) {
      body = buildQuestionString({ questionType: questions[0].questionType, question: questions[0].question, answers })
    }
  } else if (textMessageBlast?.kind === 'whatsapp' && whatsappTemplateId) {
    act.campaignBuilder.renderTemplate((data) => setWhatsappContent(data.body))
  } else {
    body =
      (contentVariants.find((cv) => cv.id === currentContentVariantId) || {}).content
        ?.body || ''
  }
  if (textMessageBlast?.kind === 'sms') {
    body += '\n' + i18n.t('user.campaigns.text.opt_out_message')
  }
  const renderedBody = whatsappContent
    ? whatsappContent
    : mergeTags
      ? prettify(body || '', mergeTags)
      : ''

  const sendingInProgress =
    textMessageBlast && textMessageBlast.finishedSendingAt === null
  const allFailed =
    !sendingInProgress && textMessagesCount > 0 && failedCount === textMessagesCount && deliveredCount === 0
  const previewComponents = [
    <Preview
      key={'preview'}
      message="Text"
      renderedBody={renderedBody}
      textMessageBlast={textMessageBlast}
    />,
  ].concat(
    links
      ? [
          <Links
            key={'links'}
            links={links}
            finishedSending={
              isWorkflowControlled || textMessageBlast?.finishedSendingAt !== null
            }
          />,
        ]
      : []
  )

  // load merge tags
  useEffect(() => {
    act.local.loadMergeTags().then(({ data }) => {
      setMergeTags(data)
    })
  }, [])

  // load script & question stats
  useEffect(() => {
    if (surveyId) {
      scriptActions.scriptBuilder.load(surveyId)
      const questionInterval = setInterval(
        () => scriptActions.scriptBuilder.load(surveyId),
        5000
      )

      return () => {
        clearInterval(questionInterval)
      }
    }
  }, [surveyId])

  if (!textMessageBlast) {
    return <Loading text="Loading" />
  }

  const menuItems = [
    { name: 'Summary', tag: 'summary' },
    { name: 'Analytics', tag: 'analytics' },
    { name: 'Preview', tag: 'preview' },
  ]
  if (sendingInProgress) {
    menuItems.unshift({ name: 'Progress', tag: 'progress' })
  }
  if (campaignType === 'sms_survey') {
    menuItems.push({ name: 'Questions', tag: 'questions' })
  }

  const message = textMessageBlast?.kind === 'whatsapp' ? 'Message' : 'Text'

  const stats = [
    { value: textMessagesCount || 0, label: message + 's sent' },
    { value: clickedCount || 0, label: message + 's clicked' },
    { value: formatPercent(clickedCount, textMessagesCount), label: 'Click rate' },
    { value: failedCount || 0, label: message + 's failed' },
    { value: unsubscribeCount || 0, label: 'Unsubscribed' },
  ]
  if (campaignType === 'sms_conversations') {
    stats.push({
      value: formatPercent(repliedCount, textMessagesCount),
      label: 'Reply rate',
    })
  }
  if (campaignType === 'sms_survey') {
    stats.push({
      value: formatPercent(completedCount, textMessagesCount),
      label: 'Completion rate',
    })
  }

  return (
    <div className="overview">
      <div className="left">
        {size.width >= MOBILE_SCREEN_WIDTH && <FloatingMenu items={menuItems} />}
        {size.width < MOBILE_SCREEN_WIDTH && (
          <SimplePaginator
            totalPages={campaignType === 'sms_survey' ? 4 : 3}
            currentPage={overviewPage}
            onChange={setOverviewPage}
            labels={menuItems.map((item) => item.name)}
            classes="margin-bottom"
          />
        )}
      </div>
      <div className="right">
        <StatsOverview
          overviewPage={overviewPage}
          targetsCount={targetsCount}
          entityBlast={textMessageBlast}
          sendingInProgress={sendingInProgress}
          allFailed={allFailed}
          goalCompletionsCount={goalCompletionsCount}
          stats={stats}
        />

        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 3) && (
          <Boxes id="preview" boxes={previewComponents} />
        )}

        {campaignType === 'sms_survey' && (
          <>
            {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 4) && (
              <div id="questions">
                <OverviewStats onlyQuestions={true} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
