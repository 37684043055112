import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import actionFactory from 'Shared/hooks/actionFactory'
import { actions as localActionsF } from './local'
import orderActions from 'FlowTaker/lib/orderActions'

const adapter = createEntityAdapter()

const reducer = createSlice({
  name: 'actions',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    add: adapter.addOne,
    set: adapter.setOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
  },
}).reducer

const actions = (dis, store, restClient) => {
  const localActions = localActionsF(dis, store, restClient)
  const factoryActions = actionFactory(dis, store, 'action', {}, {}, {
    afterChange: () => localActions.setDirty(true)
  })

  function persist(actionId) {
    const flowId = store.getState().builder.flow.id
    const action = store.getState().actions.entities[actionId]

    return restClient.put(`/api/flows/${flowId}/actions/${actionId}`, { flowAction: action })
      .then(({ data: action }) => {
        dis({ type: 'actions/set', payload: action })
      })
  }

  function add(data) {
    const actions = _.values(store.getState().actions.entities)
    const orderedActions = orderActions(actions)
    const lastAction = orderedActions[orderedActions.length - 1]

    // add at the last
    const id = uuidv4()
    const action = { id, ...data }

    dis({ type: 'actions/upsert', payload: action })

    // update previous last action to point to this one
    if (lastAction) {
      dis({ type: 'actions/upsert', payload: { ...lastAction, nextActionId: id } })
    }

    localActions.setDirty(true)
    dis({ type: 'local/upsert', payload: { selectedActionId: id } })
  }

  function markInactive(actionId) {
    const action = store.getState().actions.entities[actionId]
    const actions = _.values(store.getState().actions.entities).filter(a => a.active)
    const orderedActions = orderActions(actions)

    dis({ type: 'actions/upsert', payload: { ...action, active: false } })

    // find any actions whose next action is this action, and update them to the next Action of this one
    const previousActions = orderedActions.filter(a => a.nextActionId === actionId)
    previousActions.forEach(a => {
      dis({ type: 'actions/upsert', payload: { ...a, nextActionId: action.nextActionId } })
    })

    const firstActionId = actions.find(a => a.isStart)?.id
    localActions.setDirty(true)
    dis({ type: 'local/upsert', payload: { selectedActionId: firstActionId } })
  }

  return {
    persist,
    ...factoryActions,
    add,
    markInactive,
  }
}

export { reducer, actions }
export default { reducer, actions }
