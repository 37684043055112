import { useContext } from 'react';
import restClient from 'Shared/hooks/restClient'
import flowTemplate from './flowTemplate'

const actionSlices = {
  flowTemplate,
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}
