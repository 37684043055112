/* eslint-disable react/prop-types */
import Table from 'Shared/components/DataTable/Table'
import restClient from 'Shared/hooks/restClient'
import { ComponentProps, FC } from 'react'

const ManageFlowTemplates: FC<{ clientId: string }> = ({ clientId }) => {
  const tableRef = useRef(null)

  const tableProps: ComponentProps<typeof Table> = {
    table: 'flowTemplates',
    scope: { clientId },
    language: { emptyTable: 'There are no page templates yet' },
    onClick: () => {},
  }
  
  const createTemplate = () => {
    restClient.post('/api/flow_templates').then((res) => {
      window.location.href = `/admin/settings/flow_templates/${res.data.id}/edit`
    })
  }

  return (
    <>
      <div className="header">
        <h1>Page Templates</h1>
        <div className="actions">
          <button className="button primary" type="button" onClick={createTemplate}>
            Add
          </button>
        </div>
      </div>
      <div className="section">
        <div className="box">
          <Table {...tableProps} ref={tableRef} />
        </div>
      </div>
    </>
  )
}

export default ManageFlowTemplates
