import {
  createSlice,
  createReducer,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'

import { selectRootOrgRule } from './selectors'

const middleware = [...getDefaultMiddleware()]

const accessSliceInitialState = {
  rules: null,
  dirty: false,
  loading: true,
  error: null,
  description: null,
}

const updateRootOrgRule = (s, a) => {
  const rootOrgRule = selectRootOrgRule(s.rules)
  if (rootOrgRule) {
    rootOrgRule.grantToSuborganisations = a.payload
    if (a.payload === 'all') {
      // discard specific sub org type rules when giving access to all
      s.rules.organisations = s.rules.organisations.filter((o) =>
        ['all', 'none'].includes(o.grantToSuborganisations)
      )
    }
  }
}

const upsertSubOrgRule = (s, a) => {
  const existingRule = s.rules.organisations.find(
    (o) => o.grantToSuborganisations === a.payload.externalType
  )
  if (existingRule) {
    existingRule.accessKind = a.payload.accessKind
  } else {
    s.rules.organisations.push({
      id: window.ENV.ORGANISATION_ID,
      grantToSuborganisations: a.payload.externalType,
      accessKind: a.payload.accessKind,
    })
  }
}

const removeSubOrgRule = (s, a) => {
  s.rules.organisations = s.rules.organisations.filter(
    (o) => o.id !== window.ENV.ORGANISATION_ID || o.grantToSuborganisations !== a.payload
  )
}

const removeSubOrgRules = (s, a) => {
  const rootOrgRule = selectRootOrgRule(s.rules)
  s.rules.organisations = [rootOrgRule]
}

const accessSlice = createSlice({
  name: 'access',
  initialState: accessSliceInitialState,
  reducers: {
    setDescription: (s, a) => {
      s.description = a.payload
    },
    setLoading: (s, a) => {
      s.loading = a.payload
    },
    setError: (s, a) => ({ ...s, error: a.payload }),
    setDirty: (s, a) => ({ ...s, dirty: a.payload }),
    updateRules: (s, a) => {
      s.rules = a.payload
    },
    updateRootOrgRule,
    upsertSubOrgRule,
    removeSubOrgRule,
    removeSubOrgRules,
  },
})

const store = configureStore({
  middleware,
  reducer: {
    access: accessSlice.reducer,
  },
  devTools: {
    name: 'Access Control',
  },
})

export default store
