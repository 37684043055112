import Button from 'Shared/components/Button'
import Select from 'react-select'
import Loading from 'Shared/components/Loading'
import './AccessControl.scoped.scss'
import restClient from 'Shared/hooks/restClient'
import useAct from '../hooks/useAct'
import { selectRootOrgRule, selectRootAndSubOrgRules } from '../selectors'

const ORGANISATION_KINDS = JSON.parse(window.ENV.ORGANISATION_KINDS)

const ACCESS_KIND_OPTIONS = [
  { value: 'view', label: 'To view' },
  { value: 'volunteer', label: 'To volunteer' },
]

function SubOrgGrant({ label, externalType, accessKind, enabled, onChange }) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '200px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  }

  const valueOption = ACCESS_KIND_OPTIONS.find((option) => option.value === accessKind)

  const handleEnabledChange = (e) => onChange(e.target.checked, accessKind)
  const handleKindChange = (option) => {
    onChange(enabled, option.value)
  }

  return (
    <div className="box sub-organisation-kind flex-center">
      <label className="grow no-margin-bottom">
        <input type="checkbox" checked={enabled} onChange={handleEnabledChange} />
        <strong>{label}</strong>
      </label>

      <Select
        options={ACCESS_KIND_OPTIONS}
        onChange={handleKindChange}
        value={valueOption}
        styles={customStyles}
        menuPortalTarget={document.body}
        classNamePrefix="access-kind-select"
      />
    </div>
  )
}

export default function AccessControlContent({ entityType, entityId, entityName }) {
  const act = useAct()

  const [rootOrgOnly, setRootOrgOnly] = useState(true)

  const { rules, dirty, loading, error } = useSel((s) => s.access)

  const rootOrgRule = useSel((s) => selectRootOrgRule(s.access.rules))

  useEffect(() => {
    act.access.load(entityType, entityId).then((rules) => {
      // Setting initial UI state based on loaded rules
      // If there are specific grants, or access to all sub orgs is enabled,
      // pre-check the 2nd org checkbox
      setRootOrgOnly(
        selectRootAndSubOrgRules(rules).length === 1 &&
          selectRootOrgRule(rules).grantToSuborganisations === 'none'
      )
    })
  }, [])

  useEffect(() => {
    // If checking with people in my organisation only, ensure root grant type is 'none'
    if (rootOrgOnly && rootOrgRule && rootOrgRule.grantToSuborganisations === 'all') {
      act.access.updateRootOrgRule('none')
    }
  }, [rootOrgOnly])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>{error}</p>
  }

  if (!loading && !rootOrgRule) {
    return <p>Failed to load your organisation</p>
  }

  const toggleGrantAllSubOrgs = () => {
    if (rootOrgRule.grantToSuborganisations === 'none') {
      setRootOrgOnly(false)
      act.access.updateRootOrgRule('all')
    } else {
      act.access.updateRootOrgRule('none')
    }
  }

  const persist = () => {
    act.access.persist(entityType, entityId)
  }

  const handleSubOrgRuleChange = (externalType, enabled, accessKind) => {
    act.access.updateSubOrgRule(externalType, enabled, accessKind)
  }

  const setRootOrgAndNoSubOrgs = () => {
    act.access.removeSubOrgRules()
    setRootOrgOnly(true)
  }

  return (
    <div className="container">
      <div className={rootOrgOnly ? 'box active' : 'box'}>
        <label className="no-margin-bottom">
          <input
            type="checkbox"
            checked={rootOrgOnly}
            name="rootOrgOnly"
            onClick={setRootOrgAndNoSubOrgs}
          />
          <span>
            With people within my organisation{' '}
            <strong>{window.ENV.ORGANISATION_NAME}</strong>
          </span>
        </label>
        <p className="no-margin-bottom dark-grey">
          Only allow users within your organisation to manage and take part in this
          campaign.
        </p>
      </div>

      <div className={!rootOrgOnly ? 'box active' : 'box'}>
        <label className="no-margin-bottom" checked={!rootOrgOnly}>
          <input
            type="checkbox"
            onClick={() => setRootOrgOnly(false)}
            checked={!rootOrgOnly}
          />
          <span>
            With people in my organisation <strong>{window.ENV.ORGANISATION_NAME}</strong>
            , and organisations beneath
          </span>
        </label>
        <p className="no-margin-bottom dark-grey">
          Select organisations beneath yours which can manage and take part in this
          campaign.
        </p>

        {!rootOrgOnly && (
          <div className="margin-top indented">
            <label className="margin-bottom">
              <input
                type="checkbox"
                checked={rootOrgRule.grantToSuborganisations === 'all'}
                onClick={() => toggleGrantAllSubOrgs()}
              />
              <span>
                Share with <strong>all</strong> people in{' '}
                <strong>{window.ENV.ORGANISATION_NAME}</strong> and <strong>all</strong>{' '}
                organisations beneath
              </span>
            </label>

            {rootOrgRule.grantToSuborganisations === 'none' && (
              <>
                <div className="margin-top margin-bottom">
                  <strong>Select types of organisations</strong> to share with
                </div>

                {ORGANISATION_KINDS.map((kind) => {
                  // The access record matching this sub-org kind
                  const rule = rules.organisations.find(
                    (org) =>
                      org.id === window.ENV.ORGANISATION_ID &&
                      org.grantToSuborganisations === kind.external_type
                  )
                  return (
                    <SubOrgGrant
                      key={kind.external_type}
                      label={kind.label}
                      externalType={kind.external_type}
                      enabled={!!rule}
                      accessKind={rule ? rule.accessKind : 'view'}
                      onChange={(enabled, accessKind) =>
                        handleSubOrgRuleChange(kind.external_type, enabled, accessKind)
                      }
                    />
                  )
                })}
              </>
            )}
          </div>
        )}
      </div>

      <div className="grow" />

      <div className="footer">
        {dirty && (
          <Button primary dark onClick={persist}>
            Update
          </Button>
        )}
        {!dirty && (
          <Button primary green disabled>
            Saved
          </Button>
        )}
      </div>
    </div>
  )
}
