export default function LundaForm({ action, onSubmit }) {
  const embedUrl = action?.config?.embedUrl
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleMessage = (message) => {
      const { data } = message;

      // Handle iframe height adjustment
      if (data.event === "Page view" && data.data?.idealFrameHeight) {
        const iframe = document.getElementById("lunda")
        if (iframe) {
          iframe.style.height = `${data.data.idealFrameHeight}px`
        }
      }

      // Handle form payment type change event
      if (data.event === "Payment Succeeded") {
        onSubmit()
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return <div className="share-form">
    <div className="margin-top share-link-container">
      <iframe
        id="lunda"
        ref={iframeRef}
        src={embedUrl}
        frameBorder="0"
        width="100%"
        height="533"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      />
    </div>
  </div>
}
