import RedirectActionForm from 'FlowBuilder/components/ActionForms/RedirectActionForm'
import ShareActionForm from 'FlowBuilder/components/ActionForms/ShareActionForm'
import LundaActionForm from 'FlowBuilder/components/ActionForms/LundaActionForm'
import SurveyActionForm from 'FlowBuilder/components/ActionForms/SurveyActionForm'

import RedirectActionView from 'FlowBuilder/components/ActionViews/Redirect/RedirectActionView'
import SurveyActionView from 'FlowBuilder/components/ActionViews/Survey/SurveyActionView'
import ShareActionView from 'FlowBuilder/components/ActionViews/Share/ShareActionView'
import LundaActionView from 'FlowBuilder/components/ActionViews/Lunda/LundaActionView'
import DonateActionView from './ActionViews/Donate/DonateActionView'
import Setup from './Setup'

export default function Main() {
  const navItem = useSel(s => s.local.navItem)
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selectedPageNumber = useSel(s => s.local.selectedPageNumber)
  const selectedAction = useSel(s => s.actions.entities[selectedActionId])

  if (navItem === 'build') {
    if (selectedAction) {
      switch (selectedAction.kind) {
        case 'redirect':
          return <RedirectActionForm actionId={selectedActionId} />
        case 'share':
          return <ShareActionForm actionId={selectedActionId} />
        case 'lunda':
          return <LundaActionForm actionId={selectedActionId} />
        case 'survey':
          return <SurveyActionForm actionId={selectedActionId} pageNumber={selectedPageNumber} />
        default:
          return <div>Unknown action kind: {selectedAction.kind}</div>
      }
    }
  } else if (navItem === 'preview') {
    if (selectedAction) {
      switch (selectedAction.kind) {
        case 'redirect':
          return <RedirectActionView />
        case 'survey':
          return <SurveyActionView actionId={selectedActionId} pageNumber={selectedPageNumber} />
        case 'share':
          return <ShareActionView actionId={selectedActionId} />
        case 'lunda':
          return <LundaActionView actionId={selectedActionId} />
        case 'donate':
          return <DonateActionView actionId={selectedActionId} />
        default:
          return <div>Unknown action kind: {selectedAction.kind}</div>
      }
    }
  } else if (navItem === 'setup') {
    return <Setup />
  }

  return null
}
