import './AddAction.scoped.scss'
import { useAct } from 'FlowBuilder/store'

export default function AddAction() {
  const act = useAct()

  function addAction(kind) {
    return () => {
      act.actions.add({ kind, name: _.capitalize(kind), active: true })
      act.local.closeModal()
    }
  }

  const hasSurvey = useSel((s) => !!Object.values(s.actions.entities).find((action) => action.kind === 'survey'))

  return (
    <div className="action-types">
      {!hasSurvey &&
      <a className="card large selectable text-align-center" onClick={addAction('survey')}>
        <SvgIconsCircleQuestion />
        <h2>Survey</h2>
        <p>Complete a survey</p>
      </a>}

      <a className="card large selectable text-align-center" onClick={addAction('lunda')}>
        <SvgIconsDonate />
        <h2>Lunda Donation</h2>
        <p>Collect donations</p>
      </a>

      <a className="card large selectable text-align-center" onClick={addAction('share')}>
        <SvgIconsShare />
        <h2>Share</h2>
        <p>Share on social media</p>
      </a>

      <a className="card large selectable text-align-center" onClick={addAction('redirect')}>
        <SvgIconsShare />
        <h2>Redirect</h2>
        <p>Redirect to a URL</p>
      </a>
    </div>
  )
}
