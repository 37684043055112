import { useAct } from 'FlowBuilder/store'
import Header from './Header'
import Sidebar from './Sidebar/Sidebar'
import Main from './Main'
import Modal from './Modal/Modal'
import TopNav from './TopNav'
import Button from 'Shared/components/Button'
import './PageContainer.scoped.scss'

function MobileDesktopToggle() {
  const act = useAct()

  const mobile = useSel(s => s.local.mobile)

  const setMobile = (mobile) => { act.local.upsert({ mobile }) }

  return (
    <div className="flex-center gap-10 margin-left" style={{ position: 'absolute', right: '60px' }}>
      <Button small-icon-button no-padding faded-50={!mobile} onClick={() => setMobile(true)}><SvgIconsMobile /></Button>
      <Button small-icon-button no-padding faded-50={mobile} onClick={() => setMobile(false)}><SvgIconsDesktop /></Button>
    </div>
  )
}

export default function PageContainer({ flowId, nav }) {
  const act = useAct()
  const modalOpen = useSel(s => !!s.local.modal.kind)
  const navItem = useSel(s => s.local.navItem)
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selectedAction = useSel(s => s.actions.entities[selectedActionId])
  const selectedActionIsRedirect = selectedAction?.kind === 'redirect'

  useEffect(() => {
    act.builder.load(flowId)
    act.builder.loadMergeTags()
    if (nav) act.local.setNav(nav)
  }, [])

  return <div className="flow-builder">
    <div className="banner success">The Pages module is still in testing - please help us improve by reporting any issues</div>
    <Header />

    <div className='page-container margin-top'>
      <div className='flow-left'>
        <Sidebar />
      </div>
      <div className='flow-main'>
        {navItem === 'preview' && !selectedActionIsRedirect && <MobileDesktopToggle />}
        <Main />
      </div>
    </div>

    {modalOpen && <Modal />}
  </div>
}
