import { useAct } from 'FlowTaker/store'
import LundaForm from 'FlowBuilder/components/ActionViews/Lunda/LundaForm'
import TemplateRender from 'FlowBuilder/components/ActionViews/TemplateRender'

export default function LundaAction({ actionId }) {
  const act = useAct()
  const bodyHtml = useSel(s => s.taker.flow.template.body)
  const context = useSel(s => s.taker.context)
  const action = useSel(s => s.taker.flow.actions.find(a => a.id === actionId) || {})

  const handleSubmit = () => {
    return act.taker.completeAction({ actionId })
  }

  const componentMap = useMemo(() => ({
    TakeSurveyForm: <>
      <LundaForm
        action={action}
        onSubmit={handleSubmit}
      />
    </>,
  }), [])

  return <div>
    <TemplateRender htmlContent={bodyHtml} componentMap={componentMap} context={context} />
  </div>
}
