import './FormikField.scoped.scss'
import { useField, Field } from 'formik';

// Wrapper for Fomik Field that can show a validation error
const FormikField = (props) => {
  const [field, meta] = useField(props);
  const showError = !!(meta.touched && meta.error)
  const classNames = ['validated-field']
  if(showError) {
    classNames.push('with-error')
  }

  return (
    <div className={classNames.join(' ')}>
      <Field {...props} />
      {showError && <div className="error-message">{[].concat(meta.error).join(', ')}</div>}
    </div>
  )
}

export default FormikField
