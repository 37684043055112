import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Button from 'Shared/components/Button'

const confirmValue = 'close'

export default function CloseModal({ open, hide, onSubmit }) {
  const [confirmation, setConfirmation] = useState('')
  const [redirectUrl, setRedirectUrl] = useState('')

  const handleSubmit = () => {
    if (!disabled) {
      hide()
      onSubmit(redirectUrl)
    }
  }

  const disabled = (confirmation || '').trim() !== confirmValue

  if(!open) return null

  return (
    <BackdropModal onCancel={hide} open={open} maxWidth="xs" title="Are you absolutely sure?">
      <div className="field margin-bottom full-width">
        <label>
          Please type <b>{confirmValue}</b> to confirm.
        </label>
        <input
          onChange={(e) => setConfirmation(e.target.value)}
          type="text"
          name="confirmation"
          className="full-width"
          value={confirmation}
        />
      </div>

      <div class="field margin-bottom full-width">
        <label>
          Optional: enter a URL to redirect people to when they visit this page. If you don't specify one, they'll be told the page is closed for new submissions
        </label>
        <input
          onChange={(e) => setRedirectUrl(e.target.value)}
          type="text"
          name="redirectUrl"
          placeholder="Redirect URL"
          className="full-width"
          value={redirectUrl}
        />
      </div>

      <div>
        <Button red disabled={disabled} onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </BackdropModal>
  )
}

