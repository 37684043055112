import Select from 'react-select';

export default function SelectField({onChange, value, label, options, id, ...props}) {
  const valueOption = options.find((o) => o.value === value)

  return (
    <div className="field">
      <label htmlFor={id}>{label}</label>
      <Select id={id} options={options} value={valueOption} onChange={(o) => onChange(o.value)} />
    </div>
  )
}
