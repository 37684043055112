import Header from './Header'
import PageContainer from './PageContainer'
import SideBar from './SideBar'
import Loading from '../../Shared/components/Loading/Loading'
import TemplatePicker from './TemplatePicker'
import Experiments from './Experiments'

import './Builder.scoped.scss'

const Builder = ({ campaignId, emailTemplateId }) => {
  const act = useAct()
  const [dataLoaded, setDataLoaded] = useState(false)
  const [hidden, setHidden] = useState(false)

  // enabled if the feature is on, we're not in a workflow, and we're not editing a template
  const enableAbTesting = useSel(s => window.ENV.FEATURES['email_ab_testing'] && !s.campaign.isWorkflowControlled && !emailTemplateId)

  const loaded = useSel((s) =>
    (s.emailTemplate.id || !emailTemplateId) &&
    (s.campaign.id || !campaignId) &&
    s.currentUser.id &&
    s.emailBuilder.blockTemplates &&
    dataLoaded
  )

  useEffect(() => {
    if (emailTemplateId) {
      act.emailBuilder.upsert({ editingTemplate: true })
      act.emailTemplate.load(emailTemplateId)
    } else {
      act.campaign.load(campaignId)
    }
    act.campaignBuilder.loadMergeTags().
      then(() => act.user.loadMe()).
      then(() => act.emailBuilder.loadBlockTemplates()).
      then(() => setDataLoaded(true))
  }, [])

  if (!loaded) {
    return <Loading text="Loading" />
  }

  const showTemplatePicker = () => {
    act.emailBuilder.upsert({ switchingTemplate: true })
  }

  return (
    <div id="email-builder">
      <TemplatePicker />
      <Header showTemplatePicker={showTemplatePicker} />
      <section className="main">
        {enableAbTesting && (
          <>
            <div className="top margin-bottom">
              <Experiments />
            </div>
            <div className="left">
              <Experiments />
            </div>
          </>
        )}
        <div className="page">
          <PageContainer emailTemplateId={emailTemplateId} />
        </div>
        <div className={`right ${hidden ? 'hidden' : ''}`}>
          <SideBar setHidden={setHidden} />
        </div>
      </section>
    </div>
  )
}

export default Builder
