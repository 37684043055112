import { useAct } from 'FlowTaker/store'
import SurveyAction from './Actions/SurveyAction'
import ShareAction from './Actions/ShareAction'
import RedirectAction from './Actions/RedirectAction'
import LundaAction from './Actions/LundaAction'
import './PageContainer.scoped.scss'

export default function PageContainer({ flowId, forget }) {
  const act = useAct()
  const actionId = useSel((s) => s.taker.flowTaker.currentActionId)
  const action = useSel((s) => s.taker.flow.actions.find((a) => a.id === actionId) || {})
  const { kind, surveyId } = action
  const [loading, setLoading] = useState(true)

  const returnToEditorStyles = {
    background: 'black',
    color: 'white',
    width: '100%',
    padding: '15px',
  }

  useEffect(() => {
    act.taker.load(flowId, { forget }).finally(() => {
      setLoading(false)
    })
  }, [act, flowId, forget])

  const isAdmin = _.includes(window.ENV.CURRENT_USER_PERMISSIONS || [], 'manage_admin')

  function Content() {
    if (kind === 'survey') {
      return <SurveyAction actionId={actionId} surveyId={surveyId} />
    } else if (kind === 'share') {
      return <ShareAction actionId={actionId} />
    } else if (kind === 'redirect') {
      return <RedirectAction actionId={actionId} />
    } else if (kind === 'lunda') {
      return <LundaAction actionId={actionId} />
    } else {
      return <div>Unknown action kind: {kind}</div>
    }
  }

  if (loading || action.length === 0) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <SvgCircLoading />
      </div>
    )
  }

  return (
    <div>
      {isAdmin && (
        <div style={returnToEditorStyles}>
          <a href={`/admin/flows/${flowId}/build?nav=preview`} style={{ color: 'white' }}>
            Return to editor
          </a>
        </div>
      )}
      <Content />
    </div>
  )
}
