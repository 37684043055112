import { useAct } from 'FlowBuilder/store'
import './TopNav.scoped.scss'

export default function TopNav() {
  const act = useAct()
  const navItem = useSel(s => s.local.navItem)

  return <div className='row'>
    <div className='tabs column'>
      <div className={`tab ${navItem === 'build' ? 'selected' : ''}`} onClick={() => act.local.setNav('build')}>Build</div>
      <div className={`tab ${navItem === 'setup' ? 'selected' : ''}`} onClick={() => act.local.setNav('setup')}>Setup</div>
      <div className={`tab ${navItem === 'preview' ? 'selected' : ''}`} onClick={() => act.local.setNav('preview')}>Preview</div>
    </div>
  </div>
}
