function imageUrlFromSignedBlobId(signedBlobId) {
  if (window.ENV.CDN_DOMAIN) {
    return `${window.ENV.CDN_DOMAIN}/rails/active_storage/blobs/${signedBlobId}/image.png?client_id=${window.ENV.CLIENT_ID}`
  } else {
    return `/rails/active_storage/blobs/${signedBlobId}/image.png?client_id=${window.ENV.CLIENT_ID}`
  }
}

export default function renderContext({ flow, flowTaker }) {
  const templateFields = flow.template.fields || []
  const templateFieldsValues = flow.templateFields

  const transformedTemplateFields = _.mapValues(templateFieldsValues, (value, key) => {
    const field = templateFields.find(f => _.camelCase(f.slug) === key)
    if (!field) return value

    if (field.image) {
      if (value.includes('https://') || value.includes('data:image/webp;base64')) {
        var src = value
      } else {
        var src = imageUrlFromSignedBlobId(value)
      }

      return `<img src="${src}" alt="${field.label}" />`
    }

    return value
  })

  const currentActionKind = flow.actions.find(a => a.id === flowTaker.currentActionId)?.kind
  const flowFields = { ...flow.flowFields, currentActionKind }

  return {
    template: transformedTemplateFields,
    flow: flowFields
  }
}
