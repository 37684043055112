import * as React from 'react'

function CircleBluesky(props) {
  return (
    <svg 
      width={48} 
      height={48} 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 90 90"
      {...props}
    >
      <path 
        d="M45 90C20.147 90 0 69.853 0 45v0C0 20.147 20.147 0 45 0h0c24.853 0 45 20.147 45 45v0c0 24.853-20.147 45-45 45z" 
        fill="#3BA0FE"
      />
      <path 
        d="M29.977 25.793C36.058 30.358 42.599 39.615 45 44.582c2.402-4.967 8.942-14.224 15.023-18.789 4.388-3.294 11.497-5.843 11.497 2.267 0 1.62-.929 13.607-1.473 15.553-1.893 6.766-8.792 8.491-14.929 7.447 10.727 1.826 13.456 7.873 7.562 13.92-11.192 11.485-16.087-2.882-17.341-6.563-.23-.675-.337-.991-.339-.722-.002-.268-.109.047-.339.722-1.254 3.681-6.148 18.048-17.341 6.563-5.893-6.047-3.165-12.095 7.562-13.92-6.137 1.044-13.036-.681-14.929-7.447-.545-1.946-1.473-13.933-1.473-15.553 0-8.11 7.109-5.561 11.497-2.267z" 
        fill="#fff"
      />
    </svg>
  )
}

export default CircleBluesky
