export default (dis, store, client) => {
  function closeEditor() {
    return dis({ type: 'campaignBuilder/upsert', payload: { currentEditor: null } })
  }

  function openEditor(editor) {
    return dis({ type: 'campaignBuilder/upsert', payload: { currentEditor: editor } })
  }

  function upsert(payload) {
    dis({ type: 'campaignBuilder/upsert', payload })

    if (payload.previewTargetId) renderTemplate(null, true)
  }

  function loadAccessDescription() {
    const state = store.getState()
    const { id } = state.campaign
    return axios.get(`/api/access/campaign/${id}`).then(({ data }) => {
      dis({
        type: 'campaignBuilder/upsert',
        payload: { accessDescription: data.description },
      })
    })
  }

  function loadMergeTags() {
    // create a new axios client which bypasses the case conversion - we want to
    // load merge tags in snake case
    return axios
      .create()
      .get(`/api/render/available_merge_tags`)
      .then(({ data: mergeTags }) => {
        dis({ type: 'campaignBuilder/upsert', payload: { mergeTags } })
      })
  }

  function loadSearch(id) {
    return client.get(`/api/searches/${id}`).then(({ data }) => {
      dis({ type: 'campaign/upsert', payload: { search: data } })
    })
  }

  function loadWhatsAppTemplates() {
    const state = store.getState()
    const { campaignType } = state.campaign

    return client
      .get(`/api/whatsapp/templates`, { params: { campaignType } })
      .then(({ data }) => {
        dis({ type: 'campaignBuilder/upsert', payload: { whatsAppTemplates: data } })
      })
  }

  function loadOutboundNumbers() {
    const state = store.getState()
    return client.get('/api/outbound_numbers').then(({ data }) => {
      dis({ type: 'campaignBuilder/upsert', payload: { outboundNumbers: data } })
      return data
    })
  }

  function renderTemplate(callback, allowExternalLinks = false) {
    const state = store.getState()
    const { id, textMessageBlast, emailBlast } = state.campaign
    const targetId = state.campaignBuilder.previewTargetId

    if (textMessageBlast) {
      const { enableOptOut, senderType, interpolations, whatsappTemplateId } =
        textMessageBlast
      const currentContentVariantId = state.local.currentContentVariantId
      const template = textMessageBlast.contentVariants.find(
        ({ id }) => id === currentContentVariantId
      )?.content?.body
      const optOutViaLink = enableOptOut && senderType === 'alphanumeric'

      return client
        .post(`/api/render`, {
          render: {
            template,
            targetId,
            enableOptOut,
            optOutViaLink,
            interpolations,
            whatsappTemplateId,
          },
        })
        .then(({ data }) => {
          dis({
            type: 'campaignBuilder/upsert',
            payload: { renderedContent: data, templateRenderError: null },
          })
          if (callback) callback(data)
        })
        .catch((e) => {
          if (e.response?.status === 422) {
            const msg =
              "Your content contains errors. Please check you've closed all merge tags correctly."
            dis({ type: 'campaignBuilder/upsert', payload: { templateRenderError: msg } })
          } else {
            throw e
          }
        })
    } else if (emailBlast) {
      const currentContentVariantId = state.local.currentContentVariantId

      return client
        .get(`/api/campaigns/${id}/email_blasts/${emailBlast.id}/preview`, {
          params: {
            targetId,
            allowExternalLinks: allowExternalLinks ? 'y' : 'n',
            contentVariantId: currentContentVariantId,
          },
        })
        .then(({ data: { html, subjectLine, to, senderName } }) => {
          dis({
            type: 'campaignBuilder/upsert',
            payload: {
              renderedBody: html,
              renderedSubjectLine: subjectLine,
              renderedTo: to,
              renderedSenderName: senderName,
            },
          })
          if (callback) callback(html)
        })
    }
  }

  function sendSampleTextMessage(phoneNumber) {
    const state = store.getState()
    const { id, textMessageBlast } = state.campaign
    const targetId = state.campaignBuilder.previewTargetId

    // reset sample status
    dis({ type: 'campaignBuilder/upsert', payload: { sampleStatus: null } })
    const { enableOptOut, senderType, interpolations, whatsappTemplateId } =
      textMessageBlast
    const currentContentVariantId = state.local.currentContentVariantId
    const template = textMessageBlast.contentVariants.find(
      ({ id }) => id === currentContentVariantId
    )?.content?.body

    const optOutViaLink = enableOptOut && senderType === 'alphanumeric'

    return client
      .post(
        `/api/campaigns/${id}/text_message_blasts/${textMessageBlast.id}/send_preview`,
        {
          phoneNumber,
          render: {
            template,
            targetId,
            enableOptOut,
            optOutViaLink,
            interpolations,
            whatsappTemplateId,
          },
        }
      )
      .then(({ data: { status } }) => {
        dis({ type: 'campaignBuilder/upsert', payload: { sampleStatus: status } })
      })
      .catch(() => {
        dis({ type: 'campaignBuilder/upsert', payload: { sampleStatus: 'error' } })
      })
  }

  function sendTextMessageBlastWider(contentVariantId) {
    const state = store.getState()
    const { id, textMessageBlast } = state.campaign

    return client.post(
      `/api/campaigns/${id}/text_message_blasts/${textMessageBlast.id}/send_wider`,
      { contentVariantId }
    )
  }

  function sendEmailBlastWider(contentVariantId) {
    const state = store.getState()
    const { id, emailBlast } = state.campaign

    return client.post(`/api/campaigns/${id}/email_blasts/${emailBlast.id}/send_wider`, {
      contentVariantId,
    })
  }

  function sendSampleEmail(email, onlyAllowEmail = false) {
    const state = store.getState()
    const { id: emailBlastId } = state.campaign.emailBlast
    const { previewTargetId } = state.campaignBuilder
    const currentContentVariantId = state.local.currentContentVariantId
    const campaignId = state.campaign.id

    // reset sample status
    dis({ type: 'campaignBuilder/upsert', payload: { sampleStatus: null } })

    return client
      .post(`/api/campaigns/${campaignId}/email_blasts/${emailBlastId}/send_preview`, {
        email,
        targetId: onlyAllowEmail ? null : previewTargetId,
        contentVariantId: currentContentVariantId,
      })
      .then(({ data: { status } }) => {
        dis({ type: 'campaignBuilder/upsert', payload: { sampleStatus: status } })
      })
      .catch(() => {
        dis({ type: 'campaignBuilder/upsert', payload: { sampleStatus: 'error' } })
      })
  }

  function renderArbitraryTemplate(template, callback, enableOptOut = false) {
    if (typeof template === 'object' && !Array.isArray(template)) {
      const promises = Object.keys(template).map((key) => {
        return client
          .post(`/api/render`, {
            render: { template: template[key], enableOptOut: enableOptOut },
          })
          .then(({ data: { body } }) => {
            template[key] = body
          })
      })

      return Promise.all(promises).then(() => callback(template))
    } else {
      // If template is not an object, proceed as before

      return client
        .post(`/api/render`, { render: { template, enableOptOut: enableOptOut } })
        .then(({ data: { body } }) => callback(body))
    }
  }

  function updateEstimates(searchId) {
    const state = store.getState()
    const id = state.campaign.id
    return client
      .get(`/api/campaigns/${id}/estimates?search_id=${searchId}`)
      .then(({ data: { baseCost, contactCost, timeEstimate, creditRemaining } }) => {
        dis({
          type: 'campaignBuilder/upsert',
          payload: { baseCost, contactCost, creditRemaining, timeEstimate },
        })
      })
  }

  const dRenderTemplate = _.debounce(renderTemplate, 500)
  const dRenderArbitraryTemplate = _.debounce(renderArbitraryTemplate, 500)
  const dRenderArbitraryTemplate2 = _.debounce(renderArbitraryTemplate, 500)

  return {
    closeEditor,
    openEditor,
    upsert,
    loadMergeTags,
    loadAccessDescription,
    loadSearch,
    sendSampleTextMessage,
    sendTextMessageBlastWider,
    sendSampleEmail,
    sendEmailBlastWider,
    updateEstimates,
    loadWhatsAppTemplates,
    loadOutboundNumbers,
    renderTemplate: dRenderTemplate,
    renderArbitraryTemplate: dRenderArbitraryTemplate,
    renderArbitraryTemplate2: dRenderArbitraryTemplate2,
  }
}
