export default function flow(dis, store, client) {
  async function load(id) {
    return client.get(`/api/flows/${id}`)
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: data })
      })
  }

  async function loadStats(id) {
    return client.get(`/api/flows/${id}/stats`)
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: { stats: data } })
      })
  }

  async function close(id, redirectUrl) {
    return client.post(`/api/flows/${id}/close`, {flow: {redirectUrl}})
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: data })
      })
  }

  async function reopen(id) {
    return client.post(`/api/flows/${id}/reopen`)
      .then(({ data }) => {
        dis({ type: 'flow/upsert', payload: data })
      })
  }

  return { load, loadStats, close, reopen }
}
