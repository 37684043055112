import Loading from '../../Shared/components/Loading/Loading'
import useAct from '../useAct'
import Form from './Form'

const Builder = ({ flowTemplateId }) => {
  const act = useAct()
  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    if (flowTemplateId) {
      act.flowTemplate.load(flowTemplateId).then(() => setDataLoaded(true))
    }
  }, [flowTemplateId])

  if (!dataLoaded) {
    return <Loading text="Loading" />
  }

  return (
    <div id="flow-template-builder">
      <Form />
    </div>
  )
}

export default Builder
